import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  ILocalStorageStation,
  IStation,
  updateStation,
} from "../../../../src/redux/slice/CashRegisterSlice";
import { RootState } from "../../../../src/redux/store";
import { useTranslation } from "../../../util/translation-provider";
import { PosLayout, CustomButton } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import AddCashModal from "./AddCashModal";
import RemoveCashModal from "./RemoveCashModal";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { formatCurrency } from "../../../util";
import { openCashierAction } from "../../../redux/actions/cashierAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

const CashRegisterStartPage: React.FC = () => {
  // const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const [isAddCashModalOpen, setIsAddCashModalOpen] = useState(false);
  const [isRemoveCashModalOpen, setIsRemoveCashModalOpen] = useState(false);
  // const [isOpeningAccessReqModalOpen, setIsOpeningAccessReqModalOpen] =
  //   useState(true);
  const [activeButtonType, setActiveButtonType] = useState<
    "viewAll" | "dayEnd" | null
  >(null);

  const [isOpeningBalanceEnabled, setIsOpeningBalanceEnabled] = useState(false);
  // const [isCashRegisterModalOpen, setIsCashRegisterModalOpen] = useState(false);
  const [temporaryStation, setTemporaryStation] = useState<IStation | null>(
    null
  );
  const [activeStation, setActiveStation] = useState<IStation | null>(null);

  const {
    // bills, coins, totalOpeningBalance, isStartClicked
    // activeStation,
    totalSales,
    totalRefunds,
    registerTotal,
    stationList,
    openCashierResponse,
    openCashierError,
  } = useAppSelector((state: RootState) => state.cashRegister);
  const { activeStation: activeCashier } = useAppSelector(
    (state) => state.cashier
  );

  useEffect(() => {
    const p = localStorage.getItem("activeStation");
    if (p) {
      const parsedP: ILocalStorageStation = JSON.parse(p);
      const updatedDate = new Date(parsedP.updatedOn);
      // check is parsedP.updatedOn is within currentDate
      if (new Date().getDate() === updatedDate.getDate()) {
        setActiveStation(parsedP);
      } else {
        setActiveStation({
          id: activeCashier?.id ?? 1,
          name: activeCashier?.name ?? "Cashier 1",
          cash: null,
          closingCash: null,
          totalSales: 100,
          registerTotal: 200,
          refundTotal: 5,
          extraAdditions: [],
          extraDeductions: [],
          status: null,
        });
        localStorage.setItem(
          "activeStation",
          JSON.stringify({
            id: activeCashier?.id ?? 1,
            name: activeCashier?.name ?? "Cashier 1",
            cash: null,
            closingCash: null,
            totalSales: 100,
            registerTotal: 200,
            refundTotal: 5,
            extraAdditions: [],
            extraDeductions: [],
            status: null,
            updatedOn: new Date().toISOString(),
          })
        );
      }
    } else {
      setActiveStation({
        id: activeCashier?.id ?? 1,
        name: activeCashier?.name ?? "Cashier 1",
        cash: null,
        closingCash: null,
        totalSales: 100,
        registerTotal: 200,
        refundTotal: 5,
        extraAdditions: [],
        extraDeductions: [],
        status: null,
      });
      localStorage.setItem(
        "activeStation",
        JSON.stringify({
          id: activeCashier?.id ?? 1,
          name: activeCashier?.name ?? "Cashier 1",
          cash: null,
          closingCash: null,
          totalSales: 100,
          registerTotal: 200,
          refundTotal: 5,
          extraAdditions: [],
          extraDeductions: [],
          status: null,
          updatedOn: new Date().toISOString(),
        })
      );
    }
  }, [activeCashier]);

  useEffect(() => {
    if (activeStation) {
      setTemporaryStation(activeStation);
    } else {
      setTemporaryStation(null);
    }
  }, [activeStation]);

  // const handleManagerCodeClick = (type: ModalType) => {
  //   const config = modalConfig[type];
  //   setModalTitle(config.title);
  //   setModalTask(config.task);
  //   setModalType(type);
  //   // setIsOpeningAccessReqModalOpen(true);
  // };

  // const handleModalClose = () => {
  //   setIsOpeningAccessReqModalOpen(false);
  //   setModalType(null);
  //   setModalTitle("");
  //   setModalTask("");
  // };

  const translations = useTranslation();
  const navigate = useNavigate();

  // const handleInputChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   type: "bills" | "coins",
  //   key: string
  // ) => {
  //   const value = Number(e.target.value.replace(/[^0-9]/g, ""));
  //   if (type === "bills") {
  //     const updatedBills = { ...bills, [key]: value };
  //     dispatch(setBills(updatedBills));
  //     calculateTotal(updatedBills, coins);
  //   } else if (type === "coins") {
  //     const updatedCoins = { ...coins, [key]: value };
  //     dispatch(setCoins(updatedCoins));
  //     calculateTotal(bills, updatedCoins);
  //   }
  // };
  const onCashChange = (
    cashType: "1" | "5" | "10" | "20" | "1c" | "5c" | "10c" | "25c",
    amount: number
  ) => {
    if (temporaryStation) {
      const coinValues = {
        ...(cashType.endsWith("c") ? { [cashType]: amount } : {}),
      };
      const billValues = {
        ...(cashType.endsWith("c") ? {} : { [cashType]: amount }),
      };
      const updatedCashValues = {
        bills: {
          ...(temporaryStation?.cash?.bills ?? {
            "1": 0,
            "5": 0,
            "10": 0,
            "20": 0,
          }),
          ...billValues,
        },
        coins: {
          ...(temporaryStation?.cash?.coins ?? {
            "1c": 0,
            "5c": 0,
            "10c": 0,
            "25c": 0,
          }),
          ...coinValues,
        },
      };
      const updatedStation: IStation = {
        ...temporaryStation,
        cash: updatedCashValues,
      };
      // dispatch(updateStation(updatedStation));
      setTemporaryStation(updatedStation);
    }
    // dispatch(setBills(updatedBills));
    // calculateTotal(updatedBills, coins);
  };

  const addExtraAddition = ({
    amount,
    reason,
  }: {
    amount: number;
    reason: string;
  }) => {
    if (temporaryStation) {
      const updatedStation: IStation = {
        ...temporaryStation,
        extraAdditions: [
          ...(temporaryStation?.extraAdditions ?? []),
          { amount, reason },
        ],
      };
      setTemporaryStation(updatedStation);
    }
  };
  const addExtraDeduction = ({
    amount,
    reason,
  }: {
    amount: number;
    reason: string;
  }) => {
    if (temporaryStation) {
      const updatedStation: IStation = {
        ...temporaryStation,
        extraDeductions: [
          ...(temporaryStation?.extraDeductions ?? []),
          { amount, reason },
        ],
      };
      setTemporaryStation(updatedStation);
    }
  };

  // const calculateTotal = (
  //   bills: Record<string, number>,
  //   coins: Record<string, number>
  // ) => {
  //   const total =
  //     bills["1"] * 1 +
  //     bills["5"] * 5 +
  //     bills["10"] * 10 +
  //     bills["20"] * 20 +
  //     coins["1c"] * 0.01 +
  //     coins["5c"] * 0.05 +
  //     coins["10c"] * 0.1 +
  //     coins["25c"] * 0.25;

  //   dispatch(setTotalOpeningBalance(parseFloat(total.toFixed(2))));
  // };

  const calculateCoinTotal = () => {
    if (!temporaryStation?.cash) return 0;
    const total =
      temporaryStation?.cash?.coins["1c"] * 0.01 +
      temporaryStation?.cash?.coins["5c"] * 0.05 +
      temporaryStation?.cash?.coins["10c"] * 0.1 +
      temporaryStation?.cash?.coins["25c"] * 0.25;
    return total;
  };
  const calculateBillTotal = () => {
    if (!temporaryStation?.cash) return 0;
    const total =
      temporaryStation?.cash?.bills["1"] * 1 +
      temporaryStation?.cash?.bills["5"] * 5 +
      temporaryStation?.cash?.bills["10"] * 10 +
      temporaryStation?.cash?.bills["20"] * 20;
    return total;
  };
  const calculateExtraAdditionTotal = () => {
    if (!activeStation?.extraAdditions) return 0;
    return activeStation?.extraAdditions.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
  };
  const calculateExtraDeductionTotal = () => {
    if (!activeStation?.extraDeductions) return 0;
    return activeStation?.extraDeductions.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
  };

  // const handleAddCash = (amount: number) => {
  //   setCashAdded((prev) => prev + amount);
  // };

  // const handleRemoveCash = (amount: number) => {
  //   setCashRemoved((prev) => prev + amount);
  // };

  // const handleBackClick = () => {
  //   navigate("/pos/lobby");
  // };

  // useEffect(() => {
  //   if (isStartClicked) {
  //     setIsOpeningBalanceClicked(true);
  //     setIsOpeningBalanceEnabled(true);
  //     setStartButtonLabel("SUBMIT");
  //   }
  // }, [isStartClicked]);

  // const handleStartClick = () => {
  //   if (startButtonLabel === "START") {
  //     setStartButtonLabel("SUBMIT");
  //     dispatch(setStartClicked(true));
  //     sessionStorage.setItem("openCashRegisterModal", "true");
  //     navigate("/pos/lobby");
  //   }
  // };

  // useEffect(() => {
  //   const shouldOpenCashRegisterModal = sessionStorage.getItem(
  //     "openCashRegisterModal"
  //   );

  //   if (shouldOpenCashRegisterModal === "true") {
  //     setIsCashRegisterModalOpen(true);
  //     sessionStorage.removeItem("openCashRegisterModal");
  //   } else {
  //     setIsCashRegisterModalOpen(false);
  //   }
  // }, []);

  const buttonList = [
    {
      label: "Open Station",
      onClick: () => {
        setIsOpeningBalanceEnabled(true);
        // if (activeStation) {
        //   dispatch(updateStation({ ...activeStation, status: "Open" }));
        // }
        if (temporaryStation) {
          setTemporaryStation({ ...temporaryStation, status: "Open" });
        }
        // dispatch(openCashierAction({ cashierPointId: 1, userId: 1 }));
      },
      disabled: temporaryStation?.status !== null,
    },
    {
      label: "Add Cash",
      onClick: () => {
        setIsAddCashModalOpen(true);
      },
      disabled: activeStation?.status === "Open" ? false : true,
    },
    {
      label: "Remove Cash",
      onClick: () => {
        setIsRemoveCashModalOpen(true);
      },
      disabled: activeStation?.status === "Open" ? false : true,
    },
    {
      label: "Close Out",
      onClick: () => {
        navigate("/pos/cash-register/close-out");
      },
      disabled:
        activeStation?.status === "Open"
          ? activeStation?.status === "Open" &&
            ((temporaryStation?.extraAdditions?.length ?? 0) >
              (activeStation?.extraAdditions?.length ?? 0) ||
              (temporaryStation?.extraDeductions?.length ?? 0) >
                (activeStation?.extraDeductions?.length ?? 0))
          : true,
    },
    {
      label: activeStation?.status === "Open" ? "SUBMIT" : "START",
      onClick: async () => {
        if (temporaryStation) {
          // await dispatch(updateStation(temporaryStation));
          localStorage.setItem(
            "activeStation",
            JSON.stringify({
              ...temporaryStation,
              updatedOn: new Date().toISOString(),
            })
          );
          setActiveStation(temporaryStation);
          if (activeStation?.status === null) {
            navigate("/pos/lobby");
          }
        }
      },
      disabled: !(
        (activeStation?.status === null &&
          temporaryStation?.status === "Open" &&
          temporaryStation?.cash !== null) ||
        (activeStation?.status === "Open" &&
          ((temporaryStation?.extraAdditions?.length ?? 0) >
            (activeStation?.extraAdditions?.length ?? 0) ||
            (temporaryStation?.extraDeductions?.length ?? 0) >
              (activeStation?.extraDeductions?.length ?? 0)))
      ),
    },
  ];

  return (
    <PosLayout>
      <>
        <PosInnerPageLayout title={""}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "2.85714286rem",
                    lineHeight: "3.35714286rem",
                    color: Colors.TEXT_GRAY_DARK,
                    fontWeight: "700",
                    marginLeft: "6rem",
                  }}
                >
                  {translations.companyName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.71428571rem",
                    lineHeight: "1.92857143rem",
                    color: Colors.TEXT_GRAY_DARK,
                    marginLeft: "6rem",
                  }}
                >
                  Cash Register
                </Typography>
              </Box>

              <Box>
                <CustomButton
                  onClick={() => navigate("/pos/lobby")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    marginRight: "1rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                  }}
                >
                  Lobby
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/view-all")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    marginRight: "1rem",
                    backgroundColor:
                      activeButtonType === "viewAll"
                        ? Colors.PRIMARY
                        : Colors.BACKSHADE_GRAY,
                    color:
                      activeButtonType === "viewAll"
                        ? Colors.WHITE
                        : Colors.PRIMARY,
                    border:
                      activeButtonType === "viewAll"
                        ? "none"
                        : `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                  }}
                >
                  View All
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/day-end")}
                  disabled={stationList.some(
                    (station) => station.status !== "Closed"
                  )}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    backgroundColor:
                      activeButtonType === "dayEnd"
                        ? Colors.PRIMARY
                        : Colors.BACKSHADE_GRAY,
                    color:
                      activeButtonType === "dayEnd"
                        ? Colors.WHITE
                        : Colors.PRIMARY,
                    border:
                      activeButtonType === "dayEnd"
                        ? "none"
                        : `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    marginTop: "6rem",
                    marginRight: "7.5rem",
                  }}
                >
                  Day End
                </CustomButton>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: Colors.BACKSHADE_GRAY,
                margin: "2rem",
                width: "120.7142857142857rem",
                height: "52.92857142857143rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: "0.7142857142857143rem",
                padding: "2rem",
                marginTop: "-1rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: Colors.MAIN_GRAY,
                    fontSize: "2.285714285714286rem",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  Opening Balance
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "2rem",
                }}
              >
                {/* station 1 */}
                <Box
                  sx={{
                    width: "40rem",
                    padding: "0.3rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.3rem",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: Colors.SECONDARY,
                      color: Colors.WHITE,
                      height: "6rem",
                      textAlign: "center",
                      paddingTop: "2rem",
                      fontSize: "1.714285714285714rem",
                      borderRadius:
                        "0.7142857142857143rem 0.7142857142857143rem 0 0",
                    }}
                  >
                    {/* {temporaryStation?.name ?? "N/A"} */}
                    {activeCashier?.name ?? "N/A"}
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "0.3rem",
                      height: "5rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        padding: "1rem",
                        textAlign: "center",
                        paddingTop: "1.5rem",
                        fontSize: "1.428571428571429rem",
                      }}
                    >
                      Bills
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        padding: "1rem",
                        textAlign: "center",
                        paddingTop: "1.5rem",
                        fontSize: "1.428571428571429rem",
                      }}
                    >
                      Coins
                    </Box>
                  </Box>

                  {/* 4-Column Grid */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "0.3rem",
                      maxHeight: "22rem",
                      padding: "0",
                      backgroundColor: Colors.BACKSHADE_GRAY,
                    }}
                  >
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        $1
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.bills["1"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("1", Number(sanitizedValue));
                            // handleInputChange(e, "bills", item.keyDollar);
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        ¢1
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.coins["1c"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("1c", Number(sanitizedValue));
                            // handleInputChange(e, "coins", item.keyCent);
                          }}
                        />
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        $5
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.bills["5"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("5", Number(sanitizedValue));
                            // handleInputChange(e, "bills", item.keyDollar);
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        ¢5
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.coins["5c"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("5c", Number(sanitizedValue));
                            // handleInputChange(e, "coins", item.keyCent);
                          }}
                        />
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        $10
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.bills["10"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("10", Number(sanitizedValue));
                            // handleInputChange(e, "bills", item.keyDollar);
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        ¢10
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.coins["10c"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("10c", Number(sanitizedValue));
                            // handleInputChange(e, "coins", item.keyCent);
                          }}
                        />
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        $20
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.bills["20"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("20", Number(sanitizedValue));
                            // handleInputChange(e, "bills", item.keyDollar);
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        ¢25
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: isOpeningBalanceEnabled
                            ? Colors.WHITE
                            : Colors.ACCENT_GRAY,
                          height: "5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90%",
                            height: "80%",
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            backgroundColor: "transparent",
                          }}
                          placeholder=""
                          value={`x ${
                            temporaryStation?.cash?.coins["25c"] ?? "0"
                          }`}
                          disabled={!isOpeningBalanceEnabled}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            e.target.value = sanitizedValue;
                            onCashChange("25c", Number(sanitizedValue));
                            // handleInputChange(e, "coins", item.keyCent);
                          }}
                        />
                      </Box>
                    </>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "0.3rem",
                      height: "5rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.WHITE,
                        color: Colors.MAIN_GRAY,
                        padding: "1rem",
                        textAlign: "center",
                        fontSize: "1.428571428571429rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Total
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.WHITE,
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.428571428571429rem",
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                        placeholder=""
                        value={calculateBillTotal()}
                        disabled
                        // onChange={(e) => {
                        //   const sanitizedValue = e.target.value.replace(
                        //     /[^0-9]/g,
                        //     ""
                        //   );
                        //   e.target.value = sanitizedValue;
                        //   handleInputChange(e, "bills", "newBillsValue");
                        // }}
                      />
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.WHITE,
                        color: Colors.MAIN_GRAY,
                        padding: "1rem",
                        textAlign: "center",
                        fontSize: "1.428571428571429rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Total
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.WHITE,
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.428571428571429rem",
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                        placeholder=""
                        value={calculateCoinTotal()}
                        disabled
                        // onChange={(e) => {
                        //   const sanitizedValue = e.target.value.replace(
                        //     /[^0-9]/g,
                        //     ""
                        //   );
                        //   e.target.value = sanitizedValue;
                        //   handleInputChange(e, "coins", "newCoinsValue");
                        // }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: Colors.MAIN_GRAY,
                      color: Colors.WHITE,
                      height: "5rem",
                      padding: "1rem",
                      textAlign: "left",
                      fontSize: "1.428571428571429rem",
                      borderRadius:
                        "0 0 0.7142857142857143rem 0.7142857142857143rem",
                      paddingTop: "1.5rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Total Opening Balance:</span>
                    <span>
                      {formatCurrency({
                        amount: calculateBillTotal() + calculateCoinTotal(),
                        prefix: "$",
                      })}
                    </span>
                  </Box>
                </Box>

                {/* balance summary Section  */}
                <Box
                  sx={{
                    width: "40rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "0.7142857142857143rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: Colors.SECONDARY,
                      color: Colors.WHITE,
                      height: "6rem",
                      textAlign: "center",
                      paddingTop: "2rem",
                      fontSize: "1.714285714285714rem",
                      borderRadius:
                        "0.7142857142857143rem 0.7142857142857143rem 0 0",
                    }}
                  >
                    Balance Summary
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "0.3rem",
                      padding: "0.3rem",
                    }}
                  >
                    {/* {[
                      "Openning Balance",
                      "Cash Added",
                      "Cash Removed",
                      "Total Sales",
                      "Register Total",
                      "Total Refunds Amount",
                    ].map((text, index) => (
                      <React.Fragment key={index}>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            color: Colors.MAIN_GRAY,
                            padding: "1rem",
                            textAlign: "center",
                            fontSize: "1.428571428571429rem",
                            display: "flex",
                            alignItems: "flex-start",
                            paddingLeft: "3rem",
                          }}
                        >
                          {text}
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            fontSize: "1.428571428571429rem",
                            color: Colors.MAIN_GRAY,
                            border: "none",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "flex-start",
                            paddingLeft: "3rem",
                            paddingTop: "1rem",
                          }}
                        >
                          {index === 0
                            ? `$${totalOpeningBalance}`
                            : index === 1
                            ? `$${cashAdded}`
                            : index === 2
                            ? `$${cashRemoved}`
                            : null}
                        </Box>
                      </React.Fragment>
                    ))} */}
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                        }}
                      >
                        Opening Balance
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          fontSize: "1.428571428571429rem",
                          color: Colors.MAIN_GRAY,
                          border: "none",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {temporaryStation?.cash
                          ? calculateBillTotal() + calculateCoinTotal()
                          : "0"}
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                        }}
                      >
                        Cash Added
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          fontSize: "1.428571428571429rem",
                          color: Colors.MAIN_GRAY,
                          border: "none",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {calculateExtraAdditionTotal()}
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                        }}
                      >
                        Cash Removed
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          fontSize: "1.428571428571429rem",
                          color: Colors.MAIN_GRAY,
                          border: "none",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {calculateExtraDeductionTotal()}
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                        }}
                      >
                        Total Sales
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          fontSize: "1.428571428571429rem",
                          color: Colors.MAIN_GRAY,
                          border: "none",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {activeStation?.totalSales ?? "0"}
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                        }}
                      >
                        Register Total
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          fontSize: "1.428571428571429rem",
                          color: Colors.MAIN_GRAY,
                          border: "none",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {activeStation?.registerTotal ?? "0"}
                      </Box>
                    </>
                    <>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                        }}
                      >
                        Total Refunds
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          height: "5rem",
                          fontSize: "1.428571428571429rem",
                          color: Colors.MAIN_GRAY,
                          border: "none",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "3rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {activeStation?.refundTotal ?? "0"}
                      </Box>
                    </>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: Colors.MAIN_GRAY,
                      color: Colors.WHITE,
                      height: "5rem",
                      padding: "1rem",
                      textAlign: "left",
                      fontSize: "1.428571428571429rem",
                      borderRadius:
                        "0 0 0.7142857142857143rem 0.7142857142857143rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Day End Balance</span>
                    <span>
                      {formatCurrency({
                        amount:
                          calculateExtraAdditionTotal() +
                          (activeStation?.totalSales ?? 0) +
                          (calculateBillTotal() + calculateCoinTotal()) -
                          (calculateExtraDeductionTotal() +
                            (activeStation?.refundTotal ?? 0)) -
                          (activeStation?.registerTotal ?? 0),
                        prefix: "$",
                      })}
                    </span>
                  </Box>
                </Box>

                {/* righ side buttons Section  */}
                <Box
                  sx={{
                    width: "30rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    display: "flex",
                    flexDirection: "column",
                    gap: "2.5rem",
                    borderRadius: "0.7142857142857143rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {buttonList.map((button, index) => (
                    <CustomButton
                      key={index}
                      sx={{
                        width: "30rem",
                        height: "6.571428571428571rem",
                        backgroundColor:
                          index === 4 ? Colors.PRIMARY : Colors.WHITE,
                        color: index === 4 ? Colors.WHITE : Colors.PRIMARY,
                        fontSize:
                          index === 4
                            ? "2.285714285714286rem"
                            : "1.428571428571429rem",
                        borderRadius: "0.7142857142857143rem",
                        border: "none",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      disabled={button.disabled}
                      onClick={button.onClick}
                    >
                      {button.label}
                    </CustomButton>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </PosInnerPageLayout>

        <AddCashModal
          open={isAddCashModalOpen}
          onClose={() => setIsAddCashModalOpen(false)}
          onAdd={addExtraAddition}
        />

        <RemoveCashModal
          open={isRemoveCashModalOpen}
          onClose={() => setIsRemoveCashModalOpen(false)}
          onRemove={addExtraDeduction}
        />

        {/* <ManagerCodeModal
          open={isOpeningAccessReqModalOpen}
          onClose={() => navigate("/pos/lobby")}
          onDone={() => {
            setIsOpeningAccessReqModalOpen(false);
          }}
          title="Cash Register"
          task="access the Cash Register"
        />

        <ManagerCodeModal
          open={isCashRegisterModalOpen}
          onClose={() => {
            setIsCashRegisterModalOpen(false);
          }}
          title={modalConfig.cashRegister.title}
          task={modalConfig.cashRegister.task}
          onDone={() => {
            setIsCashRegisterModalOpen(false);
          }}
        /> */}
      </>
    </PosLayout>
  );
};

export default CashRegisterStartPage;
