import { FC } from "react";
import { Box, Typography } from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton } from "../../../components";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

interface ClearRegisterModalProps {
  open: boolean;
  onClose: () => void;
  onClear: () => void;
}

const ClearRegisterModal: FC<ClearRegisterModalProps> = ({
  open,
  onClose,
  onClear,
}) => {
  return (
    <CustomModal
      title="Clear Register"
      openModal={open}
      onClose={onClose}
      width={pxToRem(564)}
      height={pxToRem(377)}
    >
      <Typography
        sx={{
          fontSize: pxToRem(24),
          color: Colors.MAIN_GRAY,
          textAlign: "left",
          marginTop: pxToRem(36),
        }}
      >
        This will clear all data for the day. Are you sure to clear register now?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: pxToRem(75),
        }}
      >
        <CustomButton
          onClick={onClose}
          sx={{
            width: pxToRem(216),
            height: pxToRem(64),
            backgroundColor: Colors.WHITE,
            color: Colors.PRIMARY,
            fontSize: pxToRem(24),
            border: `${pxToRem(1)} solid ${Colors.PRIMARY}`,
            borderRadius: pxToRem(5),
            whiteSpace: "nowrap",
          }}
        >
          Not Now
        </CustomButton>
        <CustomButton
          onClick={onClear}
          sx={{
            width: pxToRem(216),
            height: pxToRem(64),
            fontSize: pxToRem(24),
            backgroundColor: Colors.PRIMARY,
            color: Colors.WHITE,
            borderRadius: pxToRem(5),
            whiteSpace: "nowrap",
          }}
        >
          Clear
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default ClearRegisterModal;
