// File path: components/modals/ConfirmationModal.tsx
import { FC } from "react";
import { Box, Typography } from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton } from "../../../components";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  onPrint: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onContinue,
  onPrint,
}) => {
  return (
    <CustomModal
      title="Confirmation"
      openModal={open}
      onClose={onClose}
      width={pxToRem(564)}
      height={pxToRem(377)}
    >
      <Typography
        sx={{
          fontSize: pxToRem(24),
          color: Colors.MAIN_GRAY,
          textAlign: "left",
          marginTop: pxToRem(36),
        }}
      >
        Are you sure to continue without printing Day End Report?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: pxToRem(75),
        }}
      >
        <CustomButton
          onClick={onContinue}
          sx={{
            width: pxToRem(216),
            height: pxToRem(64),
            backgroundColor: Colors.WHITE,
            color: Colors.PRIMARY,
            fontSize: pxToRem(24),
            border: `${pxToRem(1)} solid ${Colors.PRIMARY}`,
            borderRadius: pxToRem(5),
            whiteSpace: "nowrap",
          }}
        >
          Continue Anyway
        </CustomButton>
        <CustomButton
          onClick={onPrint}
          sx={{
            width: pxToRem(216),
            height: pxToRem(64),
            fontSize: pxToRem(24),
            backgroundColor: Colors.PRIMARY,
            color: Colors.WHITE,
            borderRadius: pxToRem(5),
            whiteSpace: "nowrap",
          }}
        >
          Print Report
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default ConfirmationModal;
