import React, { FC, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CustomModal from "../custom-modal/custom-modal";
import { CustomButton } from "../..";
import { Colors } from "../../../configs";

interface IManagerCodeModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  task: string;
  onDone: () => void;
}

const ManagerCodeModal: FC<IManagerCodeModalProps> = ({
  open,
  onClose,
  title,
  task,
  onDone,
}) => {
  const [managerCode, setManagerCode] = useState<string>("");

  const handleDone = () => {
    if (managerCode.trim()) {
      onDone();
    }
  };

  return (
    <CustomModal
      title={title}
      openModal={open}
      onClose={onClose}
      width="40.28571428571429rem"
      height="28.92857142857143rem"
      disableBackdropClick={true}
      enableBlurBackground={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.428571428571429rem",
            color: Colors.MAIN_GRAY,
            textAlign: "center",
          }}
        >
          To {task}, please enter the Manager Code!
        </Typography>
        <Typography
          sx={{
            fontSize: "1.714285714285714rem",
            color: Colors.MAIN_GRAY,
            textAlign: "left",
            marginTop: "-0.5rem",
            marginLeft: "-10rem",
          }}
        >
          Enter Manager Code:
        </Typography>
        <TextField
          value={managerCode}
          onChange={(e) => setManagerCode(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleDone();
            }
          }}
          placeholder="Enter Code"
          sx={{
            width: "27.21428571428571rem",
            marginTop: "-1.5rem",
            "& .MuiOutlinedInput-root": {
              height: "3rem",
            },
            "& .MuiOutlinedInput-input": {
              textAlign: "left",
              fontSize: "1.428571428571429rem",
              color: Colors.MAIN_GRAY,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: Colors.MAIN_GRAY,
            },
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <CustomButton
          onClick={handleDone}
          disabled={!managerCode.trim()}
          sx={{
            width: "33.35714285714286rem",
            height: "4.571428571428571rem",
            backgroundColor: Colors.PRIMARY,
            fontSize: "1.714285714285714rem",
            textTransform: "none",
          }}
        >
          Done
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default ManagerCodeModal;
