import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

const tableHeaders = [
  "Station",
  "Shift",
  "Opening Balance",
  "Cash",
  "Check",
  "Credit Card",
  "Charge Customer",
  "Discount",
  "Sales",
  "Register Total",
];

const sampleData = [
  {
    station: "0",
    shift: "07:00 - 18:00",
    openingBalance: 500,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    chargeCustomer: 0.0,
    discount: 0.0,
    sales: 570.0,
    registerTotal: 100.0,
  },
  {
    station: "1",
    shift: "07:00 - 14:00",
    openingBalance: 600,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    chargeCustomer: 0.0,
    discount: 0.0,
    sales: 580.0,
    registerTotal: 110.0,
  },
  {
    station: "2",
    shift: "07:00 - 12:30",
    openingBalance: 450,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    chargeCustomer: 0.0,
    discount: 0.0,
    sales: 500.0,
    registerTotal: 900.0,
  },
  {
    station: "3",
    shift: "14:00 - 18:00",
    openingBalance: 700,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    chargeCustomer: 0.0,
    discount: 0.0,
    sales: 600.0,
    registerTotal: 120.0,
  },
  {
    station: "4",
    shift: "07:00 - 14:00",
    openingBalance: 550,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    chargeCustomer: 0.0,
    discount: 0.0,
    sales: 530.0,
    registerTotal: 970.0,
  },
  {
    station: "5",
    shift: "14:00 - 18:00",
    openingBalance: 480,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    chargeCustomer: 0.0,
    discount: 0.0,
    sales: 510.0,
    registerTotal: 940.0,
  },
  {
    station: "6",
    shift: "12:30 - 18:00",
    openingBalance: 520,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    chargeCustomer: 0.0,
    discount: 0.0,
    sales: 550.0,
    registerTotal: 990.0,
  },
];

const totalRow = ["Total", "", 746.0, 0.0, 0.0, 0.0, 0.0, 0.0, 746.0, 746.0];

const DailySnapshotTable1: React.FC = () => {
  return (
    <TableContainer
      sx={{
        width: pxToRem(1561),
        height: pxToRem(398),
        borderRadius: pxToRem(12),
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          maxHeight: pxToRem(398),
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": { width: "1.6rem" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ADADAD",
            border: "0.6rem solid transparent",
            backgroundClip: "padding-box",
            minHeight: "3rem",
          },
          "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
        }}
      >
        <Table
          stickyHeader
          sx={{ tableLayout: "fixed", borderSpacing: pxToRem(2) }}
        >
          <TableHead>
            <TableRow
              sx={{ backgroundColor: Colors.SECONDARY, height: pxToRem(41) }}
            >
              {tableHeaders.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontSize: pxToRem(18),
                    fontWeight: "bold",
                    color: Colors.WHITE,
                    height: pxToRem(41),
                    minHeight: pxToRem(41),
                    lineHeight: pxToRem(41),
                    backgroundColor: Colors.SECONDARY,
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sampleData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: Colors.ACCENT_GRAY,
                  height: pxToRem(41),
                }}
              >
                {Object.values(row).map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      fontSize: pxToRem(18),
                      color: Colors.MAIN_GRAY,
                      height: pxToRem(41),
                      minHeight: pxToRem(41),
                      lineHeight: pxToRem(41),
                      padding: "0px",
                      textAlign: "center",
                    }}
                  >
                    {typeof cell === "number" ? cell.toFixed(2) : cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {/* Last Row */}
            <TableRow
              sx={{ backgroundColor: Colors.MAIN_GRAY, height: pxToRem(41) }}
            >
              {totalRow.map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  sx={{
                    fontSize: pxToRem(18),
                    fontWeight: "bold",
                    color: Colors.WHITE,
                    height: pxToRem(41),
                    minHeight: pxToRem(41),
                    lineHeight: pxToRem(41),
                    borderBottomLeftRadius: cellIndex === 0 ? pxToRem(5) : 0,
                    borderBottomRightRadius:
                      cellIndex === totalRow.length - 1 ? pxToRem(5) : 0,
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  {typeof cell === "number" ? cell.toFixed(2) : cell}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default DailySnapshotTable1;
