import { FC, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { AddDamagesDrawer } from "../../../../components/admin/add-damages-drawer/AddDamagesDrawer";

interface IDamage {
  id: number;
  damage: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const DamagesPage: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const [selectedDamageId, setSelectedDamageId] = useState<number | undefined>(
    undefined
  );
  const [selectedDamage, setSelectedDamage] = useState<string>("");
  const [damages, setDamages] = useState<IDamage[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    id: number,
    damage: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDamageId(id);
    setSelectedDamage(damage);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDrawerClose = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleAddDamage = () => {
    setOpenDrawer(true);
    setSelectedDamageId(undefined);
    setSelectedDamage("");
  };

  const handleEditDamage = () => {
    handleClose();
    setOpenDrawer(true);
  };

  const handleUpdateDamage = (newDamage: IDamage) => {
    setDamages((prevDamages) => {
      const existingIndex = prevDamages.findIndex(
        (damage) => damage.id === newDamage.id
      );
      if (existingIndex !== -1) {
        const updatedDamages = [...prevDamages];
        updatedDamages[existingIndex] = newDamage;
        return updatedDamages;
      } else {
        return [...prevDamages, newDamage];
      }
    });
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.357rem",
          paddingInline: "5rem",
          width: "100%",
        }}
      >
        <AddDamagesDrawer
          open={openDrawer}
          toggleDrawer={handleOnDrawerClose}
          damageId={selectedDamageId}
          damageContent={selectedDamage}
          onDamageUpdate={handleUpdateDamage}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Damages
          </Typography>
          <CustomButton
            sx={{ width: "11rem", height: "3rem" }}
            onClick={handleAddDamage}
          >
            Add New
          </CustomButton>
        </Box>

        <Box
          sx={{
            maxHeight: "51rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: "0.5rem" },
            "&::-webkit-scrollbar-thumb": { background: "#888" },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Damage</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {damages.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell sx={{ width: "80%" }}>
                    {row.damage}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "20%" }}>
                    <IconButton
                      onClick={(e) => handleClick(e, row.id, row.damage)}
                    >
                      <MoreHorizIcon
                        sx={{ fontSize: "2rem" }}
                        color="primary"
                      />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <Menu
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleClose}
              >
                <MenuItem onClick={handleEditDamage}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Damage</ListItemText>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Damage</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};
