import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponseSingle } from "../../typings/interfaces";
import { IcashierOnboardingResponse } from "../../typings/interfaces/responses/cashier-onboarding-response";
import { CASHIER_ONBOARDING } from "../../configs/apiConfig";
import { IcashierOnboardingRequest } from "../../typings/interfaces/requests/cashier-onboarding-request";

export const cashierOnboardingAction = createAsyncThunk(
  "cashier-point/create-new",
  async (
    { storeId, merchantId, deviceName, deviceType }: IcashierOnboardingRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<
        BaseResponseSingle<IcashierOnboardingResponse>
      >(CASHIER_ONBOARDING, { storeId, merchantId, deviceName, deviceType });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
