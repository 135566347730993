import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

const tableHeaders = [
  "Base",
  "Tickets In",
  "Items In",
  "Cash In",
  "Tickets Out",
  "Items Out",
  "Total Sales",
];

const sampleData = [
  {
    base: "Dry Cleaning",
    ticketsIn: 1,
    itemsIn: 6,
    cashIn: 0.0,
    ticketsOut: 1,
    itemsOut: 5,
    totalSales: 0.0,
  },
  {
    base: "Laundry",
    ticketsIn: 6,
    itemsIn: 30,
    cashIn: 0.0,
    ticketsOut: 0,
    itemsOut: 0,
    totalSales: 0.0,
  },
  {
    base: "Shoe Repair",
    ticketsIn: 0,
    itemsIn: 0,
    cashIn: 0.0,
    ticketsOut: 0,
    itemsOut: 0,
    totalSales: 0.0,
  },
  {
    base: "Household",
    ticketsIn: 0,
    itemsIn: 0,
    cashIn: 0.0,
    ticketsOut: 0,
    itemsOut: 0,
    totalSales: 0.0,
  },
  {
    base: "Alterations",
    ticketsIn: 0,
    itemsIn: 0,
    cashIn: 0.0,
    ticketsOut: 0,
    itemsOut: 0,
    totalSales: 0.0,
  },
  {
    base: "Fur/Leather",
    ticketsIn: 0,
    itemsIn: 0,
    cashIn: 0.0,
    ticketsOut: 0,
    itemsOut: 0,
    totalSales: 0.0,
  },
  {
    base: "Costumes",
    ticketsIn: 0,
    itemsIn: 0,
    cashIn: 0.0,
    ticketsOut: 0,
    itemsOut: 0,
    totalSales: 0.0,
  },
  {
    base: "Bed Wash & Fold",
    ticketsIn: 0,
    itemsIn: 0,
    cashIn: 0.0,
    ticketsOut: 0,
    itemsOut: 0,
    totalSales: 0.0,
  },
  {
    base: "Miscellaneous",
    ticketsIn: " ",
    itemsIn: " ",
    cashIn: 0.0,
    ticketsOut: " ",
    itemsOut: " ",
    totalSales: 0.0,
  },
  {
    base: "Misc. (Taxable)",
    ticketsIn: " ",
    itemsIn: " ",
    cashIn: 0.0,
    ticketsOut: " ",
    itemsOut: " ",
    totalSales: 0.0,
  },
  {
    base: "Box Storage",
    ticketsIn: " ",
    itemsIn: " ",
    cashIn: 0.0,
    ticketsOut: " ",
    itemsOut: " ",
    totalSales: 0.0,
  },
  {
    base: "Tax",
    ticketsIn: " ",
    itemsIn: " ",
    cashIn: 0.0,
    ticketsOut: " ",
    itemsOut: " ",
    totalSales: 0.0,
  },
];

const totalRow = ["Total", 7, 36, 0.0, 1, 5, 0.0];

const DailySnapshotTable2: React.FC = () => {
  return (
    <TableContainer
      sx={{
        width: pxToRem(1561),
        height: pxToRem(610),
        borderRadius: pxToRem(12),
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          maxHeight: pxToRem(610),
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": { width: "1.6rem" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ADADAD",
            border: "0.6rem solid transparent",
            backgroundClip: "padding-box",
            minHeight: "3rem",
          },
          "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
        }}
      >
        <Table
          stickyHeader
          sx={{ tableLayout: "fixed", borderSpacing: pxToRem(2) }}
        >
          <TableHead>
            <TableRow
              sx={{ backgroundColor: Colors.SECONDARY, height: pxToRem(41) }}
            >
              {tableHeaders.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontSize: pxToRem(18),
                    fontWeight: "bold",
                    color: Colors.WHITE,
                    height: pxToRem(41),
                    minHeight: pxToRem(41),
                    lineHeight: pxToRem(41),
                    backgroundColor: Colors.SECONDARY,
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sampleData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: Colors.ACCENT_GRAY,
                  height: pxToRem(41),
                }}
              >
                {Object.entries(row).map(([key, cell], cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      fontSize: pxToRem(18),
                      color: Colors.MAIN_GRAY,
                      height: pxToRem(41),
                      minHeight: pxToRem(41),
                      lineHeight: pxToRem(41),
                      padding: "0px",
                      textAlign: "center",
                    }}
                  >
                    {["cashIn", "totalSales"].includes(key) &&
                    typeof cell === "number"
                      ? cell.toFixed(2)
                      : cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            <TableRow
              sx={{ backgroundColor: Colors.MAIN_GRAY, height: pxToRem(41) }}
            >
              {totalRow.map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  sx={{
                    fontSize: pxToRem(18),
                    fontWeight: "bold",
                    color: Colors.WHITE,
                    height: pxToRem(41),
                    minHeight: pxToRem(41),
                    lineHeight: pxToRem(41),
                    borderBottomLeftRadius: cellIndex === 0 ? pxToRem(5) : 0,
                    borderBottomRightRadius:
                      cellIndex === totalRow.length - 1 ? pxToRem(5) : 0,
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  {cellIndex === 3 || cellIndex === 6
                    ? Number(cell).toFixed(2)
                    : cell}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default DailySnapshotTable2;
