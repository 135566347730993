import { createSlice } from "@reduxjs/toolkit";

import { IdamageResponse } from "../../typings/interfaces/responses";
import {
  CreateDamageAction,
  getAllDamagesAction,
} from "../actions/damagesAction";

export interface Damagestate {
  damages: IdamageResponse[] | null;
  damagesLoading: boolean;
  damagesError: string | null;

  addDamageResponse: IdamageResponse[] | null;
  addDamageLoading: boolean;
  addDamageError: string | null;
}

const initialState: Damagestate = {
  damages: null,
  damagesLoading: false,
  damagesError: null,

  addDamageResponse: null,
  addDamageLoading: false,
  addDamageError: null,
};

const damagesSlice = createSlice({
  name: "damages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDamagesAction.pending, (state) => {
        state.damages = null;
        state.damagesLoading = true;
        state.damagesError = null;
      })
      .addCase(getAllDamagesAction.fulfilled, (state, action) => {
        state.damagesError = null;
        state.damagesLoading = false;
        state.damages = (action.payload as any).body;
      })
      .addCase(getAllDamagesAction.rejected, (state, action) => {
        state.damages = null;
        state.damagesLoading = false;
        state.damagesError = (action.payload as any).message;
      });

    builder
      .addCase(CreateDamageAction.pending, (state) => {
        console.log("API Request Started...");
        state.addDamageResponse = null;
        state.addDamageLoading = true;
        state.addDamageError = null;
      })
      .addCase(CreateDamageAction.fulfilled, (state, action) => {
        console.log("API Request Successful:", action.payload);
        state.addDamageResponse = (action.payload as any).body;
        state.addDamageLoading = false;
        state.addDamageError = null;
      })
      .addCase(CreateDamageAction.rejected, (state, action) => {
        console.error("API Request Failed:", action.error);
        state.addDamageResponse = null;
        state.addDamageLoading = false;
        state.addDamageError = (action.payload as any).message;
      });
  },
});

export default damagesSlice.reducer;
export const damagesSliceActions = damagesSlice.actions;
