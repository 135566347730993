import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Colors } from "../../../configs";

interface TableData {
  station: number | string;
  openingBalance: number;
  cash: number;
  check: number;
  creditCard: number;
  monthlyCharge: number;
  discounts: number;
  sales: number;
  registerTotal: number;
  difference?: number;
}

interface RegisterBalancingTableProps {
  data: TableData[];
  totalRowData: TableData;
  editableFields: string[];
  isEditingEnabled: boolean;
  table1Data: TableData[];
}

const RegisterBalancingTable: React.FC<RegisterBalancingTableProps> = ({
  data,
  totalRowData,
  editableFields,
  isEditingEnabled,
  table1Data,
}) => {
  const [tableData, setTableData] = useState(
    data.map((row) => ({
      ...row,
      difference: 0, // Initial difference to 0
    }))
  );

  const handleInputChange = (
    rowIndex: number,
    field: string,
    value: string
  ) => {
    const newData = [...tableData];
    newData[rowIndex][field as keyof TableData] = parseFloat(value) || 0;

    const table1Row = table1Data[rowIndex];
    const table1Total = table1Row.cash + table1Row.check + table1Row.creditCard;
    const table2Total =
      newData[rowIndex].cash +
      newData[rowIndex].check +
      newData[rowIndex].creditCard;

    newData[rowIndex].difference = table1Total - table2Total;

    setTableData(newData);
  };

  const commonCellStyles = {
    textAlign: "center",
    verticalAlign: "middle",
    padding: "0.5rem",
    height: "3.714285714285714rem",
  };

  const tableStyles = {
    cell: commonCellStyles,
    headerCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.SECONDARY,
      ...commonCellStyles,
    },
    bodyCell: {
      color: Colors.MAIN_GRAY,
      backgroundColor: Colors.WHITE,
      ...commonCellStyles,
    },
    editableCell: {
      backgroundColor: Colors.WHITE,
      ...commonCellStyles,
    },
    totalRowCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.MAIN_GRAY,
      ...commonCellStyles,
    },
  };

  return (
    <TableContainer
      sx={{
        overflow: "auto",
        borderRadius: "0.714rem",
      }}
    >
      <Table
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0.2rem",
          width: "100%",
        }}
      >
        <TableHead>
          <TableRow>
            {[
              "Station",
              "Opening Balance",
              "Cash",
              "Check",
              "Credit Card",
              "Monthly Charge",
              "Discounts",
              "Sales",
              "Register Total",
              "Difference",
            ].map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  ...tableStyles.headerCell,
                  ...(index === 0
                    ? { borderRadius: "0.357rem 0 0 0" }
                    : index === 9
                    ? { borderRadius: "0 0.357rem 0 0" }
                    : {}),
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {tableData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {Object.keys(row).map((field, colIndex) => (
                <TableCell
                  key={colIndex}
                  sx={{
                    ...(editableFields.includes(field) && isEditingEnabled
                      ? tableStyles.editableCell
                      : tableStyles.bodyCell),
                    padding: 0,
                  }}
                >
                  {editableFields.includes(field) && isEditingEnabled ? (
                    <TextField
                      value={row[field as keyof TableData]}
                      onChange={(e) =>
                        handleInputChange(rowIndex, field, e.target.value)
                      }
                      sx={{
                        width: "100%",
                        "& input": {
                          textAlign: "center",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor:
                              row.difference !== 0
                                ? Colors.ERROR_RED
                                : Colors.MAIN_GRAY,
                          },
                          "&:hover fieldset": {
                            borderColor:
                              row.difference !== 0
                                ? Colors.ERROR_RED
                                : Colors.MAIN_GRAY,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor:
                              row.difference !== 0
                                ? Colors.ERROR_RED
                                : Colors.MAIN_GRAY,
                          },
                        },
                      }}
                      inputProps={{
                        style: { padding: "1rem" },
                      }}
                    />
                  ) : field === "difference" ? (
                    row.difference.toFixed(2)
                  ) : typeof row[field as keyof TableData] === "number" ? (
                    (row[field as keyof TableData] as number).toFixed(2)
                  ) : (
                    row[field as keyof TableData]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}

          <TableRow>
            {Object.values(totalRowData).map((value, index) => (
              <TableCell
                key={index}
                sx={{
                  ...tableStyles.totalRowCell,
                  ...(index === 0
                    ? { borderRadius: "0 0 0 0.357rem" }
                    : index === 9
                    ? { borderRadius: "0 0 0.357rem 0" }
                    : {}),
                }}
              >
                {typeof value === "number" ? value.toFixed(2) : value}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RegisterBalancingTable;
