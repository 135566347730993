import { FC, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { AddSpotsDrawer } from "../../../../components/admin/add-spots-drawer/AddSpotsDrawer";

interface ISpot {
  id: number;
  name: string;
  isSelected: boolean;
  minorCost: string;
  mediumCost: string;
  majorCost: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const SpotsPage: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const [selectedSpotId, setSelectedSpotId] = useState<number | undefined>(
    undefined
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [spotsData, setSpotsData] = useState<ISpot[]>([]);

  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedSpotId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDrawerClose = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleAddSpot = () => {
    setOpenDrawer(true);
    setSelectedSpotId(undefined);
  };

  const handleEditSpot = () => {
    handleClose();
    setOpenDrawer(true);
  };

  const handleSpotsUpdate = (newSpot: ISpot) => {
    setSpotsData((prevSpots) => {
      const existingIndex = prevSpots.findIndex(
        (spot) => spot.id === newSpot.id
      );

      if (existingIndex !== -1) {
        const updatedSpots = [...prevSpots];
        updatedSpots[existingIndex] = { ...newSpot };
        return updatedSpots;
      } else {
        return [...prevSpots, newSpot];
      }
    });
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.357rem",
          paddingInline: "5rem",
          width: "100%",
          maxHeight: "57.5rem",
        }}
      >
        <AddSpotsDrawer
          open={openDrawer}
          toggleDrawer={handleOnDrawerClose}
          spotId={selectedSpotId}
          spotContent={
            selectedSpotId !== undefined
              ? spotsData.find((spot) => spot.id === selectedSpotId) ??
                undefined
              : undefined
          }
          onSpotsUpdate={handleSpotsUpdate}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Spots
          </Typography>
          <CustomButton
            sx={{
              width: "11rem",
              height: "3rem",
            }}
            onClick={handleAddSpot}
          >
            Add New
          </CustomButton>
        </Box>

        <Box
          sx={{
            maxHeight: "51rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Spot</StyledTableCell>
                <StyledTableCell>Has no-fee option</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {spotsData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell sx={{ width: "25%" }}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "30%" }}>
                    {row.isSelected ? "True" : "False"}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "20%" }}>
                    <IconButton onClick={(e) => handleClick(e, row.id)}>
                      <MoreHorizIcon
                        sx={{ fontSize: "2rem" }}
                        color="primary"
                      />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleEditSpot}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Spot</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Spot</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};
