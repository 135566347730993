import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { openCashierAction } from "../actions/cashierAction";
import { ICashierOpenResponse } from "../../typings";

// interface Station {
//   stationId: number;
//   stationName: string;
//   status: "Open" | "Closed";
// }

export interface IStation {
  id: number;
  name: string;
  cash: null | {
    bills: {
      "1": number;
      "5": number;
      "10": number;
      "20": number;
    };
    coins: {
      "1c": number;
      "5c": number;
      "10c": number;
      "25c": number;
    };
  };
  closingCash: null | {
    billTotal: number;
    coins: {
      "1c": number;
      "5c": number;
      "10c": number;
      "25c": number;
    };
  };
  status: null | "Open" | "Closed";
  totalSales: number | null;
  registerTotal: number | null;
  refundTotal: number | null;
  extraAdditions: {
    amount: number;
    reason: string;
  }[];
  extraDeductions: {
    amount: number;
    reason: string;
  }[];
}
export interface ILocalStorageStation extends IStation {
  updatedOn: string;
}

interface ICashRegisterState {
  // bills: Record<string, number>;
  // coins: Record<string, number>;
  // totalOpeningBalance: number;
  // isStartClicked: boolean;
  // stations: Station[];

  activeStation: IStation | null;
  stationList: IStation[];
  totalSales: number | null;
  registerTotal: number | null;
  totalRefunds: number | null;
  openCashierResponse: ICashierOpenResponse | null;
  openCashierPending: boolean;
  openCashierError: string | null;
}

const initialState: ICashRegisterState = {
  // bills: { "1": 0, "5": 0, "10": 0, "20": 0, newBillsValue: 0 },
  // coins: { "1c": 0, "5c": 0, "10c": 0, "25c": 0, newCoinsValue: 0 },
  // totalOpeningBalance: 0,
  // isStartClicked: false,
  // stations: [
  //   { stationId: 1, stationName: "Station 01", status: "Closed" },
  //   { stationId: 2, stationName: "Station 02", status: "Closed" },
  //   { stationId: 3, stationName: "Station 03", status: "Closed" },
  // ],
  activeStation: {
    id: 1,
    name: "Station 1",
    cash: null,
    closingCash: null,
    totalSales: 100,
    registerTotal: 200,
    refundTotal: 5,
    extraAdditions: [],
    extraDeductions: [],
    status: null,
  },
  stationList: [
    {
      id: 1,
      name: "Station 1",
      cash: null,
      closingCash: null,
      totalSales: 100,
      registerTotal: 200,
      refundTotal: 5,
      extraAdditions: [],
      extraDeductions: [],
      status: null,
    },
    {
      id: 2,
      name: "Station 2",
      cash: {
        bills: {
          "1": 0,
          "5": 0,
          "10": 0,
          "20": 0,
        },
        coins: {
          "1c": 0,
          "5c": 0,
          "10c": 0,
          "25c": 0,
        },
      },
      closingCash: {
        billTotal: 0,
        coins: {
          "1c": 0,
          "5c": 0,
          "10c": 0,
          "25c": 0,
        },
      },
      totalSales: 0,
      registerTotal: 0,
      refundTotal: 0,
      extraAdditions: [],
      extraDeductions: [],
      status: "Closed",
    },
    {
      id: 2,
      name: "Station 2",
      cash: {
        bills: {
          "1": 0,
          "5": 0,
          "10": 0,
          "20": 0,
        },
        coins: {
          "1c": 0,
          "5c": 0,
          "10c": 0,
          "25c": 0,
        },
      },
      closingCash: {
        billTotal: 0,
        coins: {
          "1c": 0,
          "5c": 0,
          "10c": 0,
          "25c": 0,
        },
      },
      totalSales: 0,
      registerTotal: 0,
      refundTotal: 0,
      extraAdditions: [],
      extraDeductions: [],
      status: "Closed",
    },
  ],
  totalSales: 0,
  registerTotal: 0,
  totalRefunds: 0,
  openCashierResponse: null,
  openCashierPending: false,
  openCashierError: null,
};

const cashRegisterSlice = createSlice({
  name: "cashRegister",
  initialState,
  reducers: {
    // setBills(state, action: PayloadAction<Record<string, number>>) {
    //   state.bills = action.payload;
    // },
    // setCoins(state, action: PayloadAction<Record<string, number>>) {
    //   state.coins = action.payload;
    // },
    // setTotalOpeningBalance(state, action: PayloadAction<number>) {
    //   state.totalOpeningBalance = action.payload;
    // },
    // setNewBillsValue(state, action: PayloadAction<number>) {
    //   state.bills.newBillsValue = action.payload;
    // },
    // setNewCoinsValue(state, action: PayloadAction<number>) {
    //   state.coins.newCoinsValue = action.payload;
    // },
    // setStartClicked(state, action: PayloadAction<boolean>) {
    //   state.isStartClicked = action.payload;
    // },
    // setStationStatus(
    //   state,
    //   action: PayloadAction<{ stationId: number; status: "Open" | "Closed" }>
    // ) {
    //   const station = state.stations.find(
    //     (s) => s.stationId === action.payload.stationId
    //   );
    //   if (station) {
    //     station.status = action.payload.status;
    //   }
    // },
    changeActiveStation(state, action: PayloadAction<IStation>) {
      state.activeStation = action.payload;
    },
    updateStation(state, action: PayloadAction<IStation>) {
      const stationIndex = state.stationList.findIndex(
        (station) => station.id === action.payload.id
      );
      if (stationIndex !== -1) {
        state.stationList[stationIndex] = action.payload;
        state.activeStation = action.payload;
      }
    },
    setStationList(state, action: PayloadAction<IStation[]>) {
      state.stationList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openCashierAction.pending, (state, action) => {
      state.openCashierResponse = null;
      state.openCashierPending = true;
      state.openCashierError = null;
    });
    builder.addCase(openCashierAction.fulfilled, (state, action) => {
      state.openCashierResponse = action.payload.body;
      state.openCashierPending = false;
      state.openCashierError = null;
    });
    builder.addCase(openCashierAction.rejected, (state, action) => {
      state.openCashierResponse = null;
      state.openCashierPending = false;
      state.openCashierError = action.error.message || "Error";
    });
  },
});

export const {
  // setBills,
  // setCoins,
  // setTotalOpeningBalance,
  // setNewBillsValue,
  // setNewCoinsValue,
  // setStartClicked,
  // setStationStatus,
  updateStation,
  setStationList,
} = cashRegisterSlice.actions;

export default cashRegisterSlice.reducer;
