import { ReactNode, useState } from "react";
import ManagerCodeModal from "./manager-code-modal/ManagerCodeModal";
import { useNavigate } from "react-router-dom";

export const ManagerRoute = ({ children }: { children: ReactNode }) => {
  const [isManagerCodeModalOpen, setIsManagerCodeModalOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      {children}
      <ManagerCodeModal
        open={isManagerCodeModalOpen}
        onClose={() => {
          navigate("/pos/lobby");
        }}
        title={"Cash Register"}
        task={"to access cash register"}
        onDone={() => {
          setIsManagerCodeModalOpen(false);
        }}
      />
    </>
  );
};
