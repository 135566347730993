import { FC, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { AddInstructionsDrawer } from "../../../../components/admin/add-instructions-drawer/AddInstructionsDrawer";

interface IInstructionPage {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const InstructionsPage: FC<IInstructionPage> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const [contextMenuStore, setContextMenuStore] = useState<null | number>(null);
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedInstructionId, setSelectedInstructionId] = useState<
    number | undefined
  >(undefined);
  const [selectedInstruction, setSelectedInstruction] = useState<string>("");

  const data = [
    {
      id: 1,
      instruction: "instruction 1",
    },
    {
      id: 2,
      instruction: "instruction 2",
    },
  ];

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    id: number,
    instruction: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedInstructionId(id);
    setSelectedInstruction(instruction);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuStore(null);
  };

  const handleOnDrawerClose = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleAddInstructions = () => {
    setOpenDrawer(true);
    setSelectedInstructionId(undefined);
    setSelectedInstruction("");
  };

  const handleEditInstructions = () => {
    handleClose();
    setOpenDrawer(true);
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.35714286rem",
          paddingInline: "5rem",
          width: "100%",
          maxHeight: "57.5rem",
        }}
      >
        <AddInstructionsDrawer
          open={openDrawer}
          toggleDrawer={handleOnDrawerClose}
          instructionId={selectedInstructionId} // Optional prop, only passed when editing
          instructionContent={selectedInstruction} // Optional prop, only passed when editing
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Instructions
          </Typography>
          <CustomButton
            sx={{
              width: "11.0714286rem",
              height: "3.07142857rem",
            }}
            onClick={handleAddInstructions}
          >
            Add New
          </CustomButton>
        </Box>

        <Box
          sx={{
            maxHeight: "51.42857143rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Instruction</StyledTableCell>

                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell sx={{ width: "80%" }}>
                    {row.instruction}
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: "20%" }}>
                    <IconButton
                      onClick={(e) => handleClick(e, row.id, row.instruction)}
                    >
                      <MoreHorizIcon
                        sx={{ fontSize: "2rem" }}
                        color="primary"
                      />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleEditInstructions}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Instruction</ListItemText>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Instruction</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};
