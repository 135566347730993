import { FC, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";

interface ISpot {
  id: number;
  name: string;
  isSelected: boolean;
  minorCost: string;
  mediumCost: string;
  majorCost: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Spot name is required"),
  isSelected: Yup.boolean(),
  minorCost: Yup.string().required("Minor cost is required"),
  mediumCost: Yup.string().required("Medium cost is required"),
  majorCost: Yup.string().required("Major cost is required"),
});

export interface ICreateSpotDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  spotId?: number;
  spotContent?: ISpot;
  onSpotsUpdate: (newSpot: ISpot) => void;
}

export const AddSpotsDrawer: FC<ICreateSpotDrawerProps> = ({
  open,
  toggleDrawer,
  spotId,
  spotContent,
  onSpotsUpdate,
}) => {
  const isEdit = !!spotId;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      isSelected: false,
      minorCost: "",
      mediumCost: "",
      majorCost: "",
    },
  });

  useEffect(() => {
    if (open) {
      reset(
        isEdit && spotContent
          ? {
              name: spotContent.name,
              isSelected: spotContent.isSelected,
              minorCost: spotContent.minorCost,
              mediumCost: spotContent.mediumCost,
              majorCost: spotContent.majorCost,
            }
          : {
              name: "",
              isSelected: false,
              minorCost: "",
              mediumCost: "",
              majorCost: "",
            }
      );
    }
  }, [open, isEdit, spotContent, reset]);

  const handleAddOrEditSpot = (data: any) => {
    if (!data.name || !data.minorCost || !data.mediumCost || !data.majorCost)
      return;

    const newSpot: ISpot = {
      id: spotId ?? Date.now(),
      name: data.name,
      isSelected: data.isSelected ?? false,
      minorCost: data.minorCost,
      mediumCost: data.mediumCost,
      majorCost: data.majorCost,
    };

    onSpotsUpdate(newSpot);

    reset({
      name: "",
      isSelected: false,
      minorCost: "",
      mediumCost: "",
      majorCost: "",
    });
    toggleDrawer();
  };

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{ width: "28.65rem" }}
    >
      <form onSubmit={handleSubmit(handleAddOrEditSpot)}>
        <Box
          sx={{
            paddingInline: "1.857rem",
            paddingTop: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.4rem",
              lineHeight: "1.7rem",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            {isEdit ? "Edit Spot" : "Add a New Spot"}
          </Typography>

          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "0.85rem" }}
          >
            <Typography>Spot Name</Typography>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <CustomTextBox
                  {...field}
                  error={!!errors.name}
                  sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                  placeholder="Enter Spot Name"
                />
              )}
            />
          </Box>

          <Divider sx={{ width: "100%" }} />

          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginBottom: "-1.2rem",
            }}
          >
            Sizes
          </Typography>

          <Controller
            name="isSelected"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    sx={{
                      marginLeft: "0.8rem",
                      "& .MuiSvgIcon-root": { fontSize: "1.6rem" },
                    }}
                  />
                }
                label="Has no-fee option"
                sx={{
                  "& .MuiFormControlLabel-label": {},
                }}
              />
            )}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "-1.2rem",
            }}
          >
            <Typography>Minor</Typography>
            <Controller
              name="minorCost"
              control={control}
              render={({ field }) => (
                <CustomTextBox
                  {...field}
                  error={!!errors.minorCost}
                  sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                  placeholder="Enter Minor Cost"
                />
              )}
            />

            <Typography>Medium</Typography>
            <Controller
              name="mediumCost"
              control={control}
              render={({ field }) => (
                <CustomTextBox
                  {...field}
                  error={!!errors.mediumCost}
                  sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                  placeholder="Enter Medium Cost"
                />
              )}
            />

            <Typography>Major</Typography>
            <Controller
              name="majorCost"
              control={control}
              render={({ field }) => (
                <CustomTextBox
                  {...field}
                  error={!!errors.majorCost}
                  sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                  placeholder="Enter Major Cost"
                />
              )}
            />
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "1rem",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "1rem",
              backgroundColor: Colors.WHITE,
            }}
          >
            <CustomButton
              sx={{ width: "9.2rem", height: "2.6rem" }}
              type="submit"
            >
              {isEdit ? "Edit Spot" : "Add Spot"}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </CustomDrawer>
  );
};
