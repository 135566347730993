import { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Controller, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { useAppDispatch } from "../../../hooks";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";

interface IInstructionProps {
  instruction: string;
}

const schema = Yup.object().shape({
  id: Yup.string().notRequired(),
  instruction: Yup.string().required("Instruction is required"),
});

type FormData = Yup.InferType<typeof schema>;
const resolver: Resolver<FormData> = yupResolver(schema);

export interface ICreateDiscountDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  instructionId?: number;
  instructionContent: string;
}

export const AddInstructionsDrawer: FC<ICreateDiscountDrawerProps> = ({
  open,
  toggleDrawer,
  instructionId,
  instructionContent,
}) => {
  const dispatch = useAppDispatch();
  const isEditMode = !!instructionId;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IInstructionProps>({
    resolver: resolver,
    defaultValues: { instruction: "" },
  });

  useEffect(() => {
    if (open) {
      reset({ instruction: instructionContent || "" });
    }
  }, [open, instructionContent, reset]);

  const handleAddInstruction = (data: IInstructionProps) => {
    console.log("Adding New Instruction:", data);
    toggleDrawer();
  };

  const handleUpdateInstruction = (data: IInstructionProps) => {
    console.log("Updating Instruction:", instructionId, data);
    toggleDrawer();
  };

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{
        width: "28.65rem",
      }}
    >
      <form
        onSubmit={handleSubmit(
          isEditMode ? handleUpdateInstruction : handleAddInstruction
        )}
      >
        <Box
          sx={{
            // width: "27.2857143rem",
            paddingInline: "1.85714286rem",
            paddingTop: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.42857143rem",
              lineHeight: "1.71428571rem",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            {isEditMode ? "Edit Instruction" : "Add a New Instruction"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.857142857rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.214285714rem",
              }}
            >
              <Typography>Instruction</Typography>
              <Controller
                name="instruction"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <CustomTextBox
                      {...field}
                      error={!!errors.instruction}
                      sx={{
                        width: "100%",
                        backgroundColor: Colors.WHITE,
                      }}
                      placeholder="Instruction"
                    />
                  </>
                )}
              />
            </Box>

            <CustomButton
              sx={{
                width: "9.28571429rem",
                height: "2.64285714rem",
                marginTop: "0.928571429rem",
              }}
              type="submit"
            >
              {isEditMode ? "Edit Instruction" : "Add Instruction"}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </CustomDrawer>
  );
};
