import { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CreateDamageAction } from "../../../redux/actions/damagesAction";

interface IDamage {
  id: number;
  damage: string;
}

const schema = Yup.object().shape({
  id: Yup.number().required("ID is required"),
  damage: Yup.string().required("Damage description is required"),
});

export interface ICreateDamageDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  damageId?: number;
  damageContent?: string;
  onDamageUpdate: (newDamage: IDamage) => void;
}

export const AddDamagesDrawer: FC<ICreateDamageDrawerProps> = ({
  open,
  toggleDrawer,
  damageId,
  damageContent,
  onDamageUpdate,
}) => {
  const dispatch = useAppDispatch();
  const isEditMode = damageId !== undefined;
  const { addDamageResponse, addDamageLoading, addDamageError } =
    useAppSelector((state) => state.damage);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IDamage>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: damageId ?? Date.now(),
      damage: "",
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        id: damageId ?? Date.now(),
        damage: damageContent || "",
      });
    }
  }, [open, damageId, damageContent, reset]);

  useEffect(() => {
    console.log("Updated Redux State:", {
      addDamageResponse,
      addDamageLoading,
      addDamageError,
    });
  }, [addDamageResponse, addDamageLoading, addDamageError]);

  const handleSaveDamage = (data: IDamage) => {
    const payload = {
      storeId: 1,
      merchantId: 1,
      damage: data.damage,
    };

    console.log("Dispatching CreateDamageAction with payload:", payload);

    dispatch(CreateDamageAction(payload));
    toggleDrawer();
  };

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{ width: "28.65rem" }}
    >
      <form onSubmit={handleSubmit(handleSaveDamage)}>
        <Box
          sx={{
            paddingInline: "1.857rem",
            paddingTop: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.4rem",
              lineHeight: "1.7rem",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            {isEditMode ? "Edit Damage" : "Add a New Damage"}
          </Typography>

          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "0.85rem" }}
          >
            <Typography>Damage Description</Typography>
            <Controller
              name="damage"
              control={control}
              render={({ field }) => (
                <CustomTextBox
                  {...field}
                  error={!!errors.damage}
                  sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                  placeholder="Enter damage details"
                />
              )}
            />
          </Box>

          <CustomButton
            sx={{ width: "9.2rem", height: "2.6rem", marginTop: "1rem" }}
            type="submit"
          >
            {isEditMode ? "Update Damage" : "Add Damage"}
          </CustomButton>
        </Box>
      </form>
    </CustomDrawer>
  );
};
