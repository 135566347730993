import { createSlice } from "@reduxjs/toolkit";
import { cashierOnboardingAction } from "../actions/cashierOnbooardingActions";
import { IcashierOnboardingResponse } from "../../typings/interfaces/responses/cashier-onboarding-response";
import {
  getCashierStationListAction,
  getStationAccessAction,
  grantStationAccessAction,
} from "../actions/cashierAction";
import { ICashierPoint } from "../../typings";

export interface ICashierOnboardingState {
  onboardingData: IcashierOnboardingResponse | null;
  onboardingLoading: boolean;
  onboardingError: string | null;

  isCashierOpen: boolean | null;
  isCashierOpenLoading: boolean;
  isCashierOpenError: string | null;

  activeStation: ICashierPoint | null;

  stationList: ICashierPoint[];
  stationListLoading: boolean;
  stationListError: string | null;

  grantStationAccessError: string | null;
  grantStationAccessLoading: boolean;
  grantStationAccessResponse: ICashierPoint | null;
}

const initialState: ICashierOnboardingState = {
  onboardingData: null,
  onboardingLoading: false,
  onboardingError: null,

  isCashierOpen: null,
  isCashierOpenLoading: false,
  isCashierOpenError: null,

  activeStation: null,

  stationList: [],
  stationListLoading: false,
  stationListError: null,

  grantStationAccessError: null,
  grantStationAccessLoading: false,
  grantStationAccessResponse: null,
};

const cashierSlice = createSlice({
  name: "cashier",
  initialState,
  reducers: {
    setActiveStation: (state, action) => {
      state.activeStation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cashierOnboardingAction.pending, (state) => {
        state.onboardingData = null;
        state.onboardingLoading = true;
        state.onboardingError = null;
      })
      .addCase(cashierOnboardingAction.fulfilled, (state, action) => {
        state.onboardingError = null;
        state.onboardingLoading = false;
        state.onboardingData = action.payload.body;
      })
      .addCase(cashierOnboardingAction.rejected, (state, action) => {
        state.onboardingData = null;
        state.onboardingLoading = false;
        state.onboardingError =
          (action.payload as any).error ?? "Something went wrong";
      });

    builder
      .addCase(getStationAccessAction.pending, (state) => {
        state.isCashierOpen = null;
        state.isCashierOpenLoading = true;
        state.isCashierOpenError = null;
      })
      .addCase(getStationAccessAction.fulfilled, (state, action) => {
        state.isCashierOpen = action.payload.body;
        state.isCashierOpenLoading = false;
        state.isCashierOpenError = null;
      })
      .addCase(getStationAccessAction.rejected, (state, action) => {
        state.isCashierOpen = null;
        state.isCashierOpenLoading = false;
        state.isCashierOpenError =
          (action.payload as any).error ?? "Something went wrong";
      });

    builder
      .addCase(getCashierStationListAction.pending, (state) => {
        state.stationList = [];
        state.stationListLoading = true;
        state.stationListError = null;
      })
      .addCase(getCashierStationListAction.fulfilled, (state, action) => {
        state.stationList = action.payload.body;
        state.stationListLoading = false;
        state.stationListError = null;
      })
      .addCase(getCashierStationListAction.rejected, (state, action) => {
        state.stationList = [];
        state.stationListLoading = false;
        state.stationListError =
          (action.payload as any).error ?? "Something went wrong";
      });

    builder
      .addCase(grantStationAccessAction.pending, (state) => {
        state.grantStationAccessError = null;
        state.grantStationAccessLoading = true;
        state.grantStationAccessResponse = null;
      })
      .addCase(grantStationAccessAction.fulfilled, (state, action) => {
        state.grantStationAccessError = null;
        state.grantStationAccessLoading = false;
        state.grantStationAccessResponse = action.payload.body;
      })
      .addCase(grantStationAccessAction.rejected, (state, action) => {
        state.grantStationAccessError =
          (action.payload as any).error ??
          (action.payload as any).message ??
          "Something went wrong";
        state.grantStationAccessLoading = false;
      });
  },
});

export default cashierSlice.reducer;
export const { setActiveStation } = cashierSlice.actions;
