import React, { useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

import DayEndButtons from "./DayEndButtons";
import { DayEndLayout } from "./DayEndLayout";
import CustomDateTimePicker from "../../../components/atoms/custom-date-time-picker/custom-date-time-picker";
import RegisterBalancingTable from "./RegisterBalancingTable";
import RegisterNoteModal from "./RegisterNoteModal";
import { Colors } from "../../../configs";

const table1Data = [
  {
    station: "1",
    openingBalance: 56.0,
    cash: 10.0,
    check: 10.0,
    creditCard: 20.0,
    monthlyCharge: 10.0,
    discounts: 10.0,
    sales: 56.0,
    registerTotal: 56.0,
  },
  {
    station: "2",
    openingBalance: 138.0,
    cash: 20.0,
    check: 25.0,
    creditCard: 20.0,
    monthlyCharge: 20.0,
    discounts: 20.0,
    sales: 138.0,
    registerTotal: 138.0,
  },
  {
    station: "3",
    openingBalance: 138.0,
    cash: 30.0,
    check: 35.0,
    creditCard: 30.0,
    monthlyCharge: 30.0,
    discounts: 30.0,
    sales: 138.0,
    registerTotal: 138.0,
  },
];

const totalRowData = {
  station: "Total",
  openingBalance: 746.0,
  cash: 0.0,
  check: 0.0,
  creditCard: 0.0,
  monthlyCharge: 0.0,
  discounts: 0.0,
  sales: 746.0,
  registerTotal: 746.0,
};

const table2Data = [
  {
    station: "1",
    openingBalance: 56.0,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    monthlyCharge: 30.0,
    discounts: 20.0,
    sales: 56.0,
    registerTotal: 56.0,
    difference: 0,
  },
  {
    station: "2",
    openingBalance: 138.0,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    monthlyCharge: 40.0,
    discounts: 10.0,
    sales: 138.0,
    registerTotal: 138.0,
    difference: 5,
  },
  {
    station: "3",
    openingBalance: 138.0,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    monthlyCharge: 20.0,
    discounts: 15.0,
    sales: 138.0,
    registerTotal: 138.0,
    difference: 0,
  },
];

const totalRowData2 = {
  station: "Total",
  openingBalance: 746.0,
  cash: 0.0,
  check: 0.0,
  creditCard: 0.0,
  monthlyCharge: 0.0,
  discounts: 0.0,
  sales: 746.0,
  registerTotal: 746.0,
  difference: 3,
};

const RegisterBalancingPage: React.FC = () => {
  const [isRegisterBalancingClicked, setIsRegisterBalancingClicked] =
    useState(false);

  const commonCellStyles = {
    textAlign: "center",
    verticalAlign: "middle",
    padding: "0.5rem",
    height: "3.714285714285714rem",
  };

  const tableStyles = {
    cell: commonCellStyles,
    headerCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.SECONDARY,
      ...commonCellStyles,
    },
    bodyCell: {
      color: Colors.MAIN_GRAY,
      backgroundColor: Colors.WHITE,
      ...commonCellStyles,
    },
    totalRowCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.MAIN_GRAY,
      ...commonCellStyles,
    },
  };

  return (
    <DayEndLayout>
      <Box
        sx={{
          backgroundColor: Colors.BACKSHADE_GRAY,
          margin: "2rem",
          width: "120.7142857142857rem",
          height: "52.92857142857143rem",
          display: "grid",
          gridTemplateColumns: "3fr 1fr",
          gap: "2rem",
          borderRadius: " 0.7142857142857143rem",
          padding: "2rem",
          marginTop: "0.2rem",
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            padding: "1rem",
            position: "relative",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: Colors.MAIN_GRAY,
                fontSize: "2.285714285714286rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Register Balancing - 2024/10/24
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              top: "1rem",
              right: "2rem",
              whiteSpace: "nowrap",
            }}
          >
            <CustomDateTimePicker
              type="date"
              value={null}
              onChange={(date) => console.log("Selected date:", date)}
              sx={{
                width: "19.14285714285714rem",
                height: "3rem",
              }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflowX: "auto",
              marginTop: "-1rem",
            }}
          >
            <TableContainer
              sx={{
                overflow: "auto",
                borderRadius: "0.714rem",
              }}
            >
              <Table
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0.2rem",
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableRow>
                    {[
                      "Station",
                      "Opening Balance",
                      "Cash",
                      "Check",
                      "Credit Card",
                      "Monthly Charge",
                      "Discounts",
                      "Sales",
                      "Register Total",
                    ].map((header, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          ...tableStyles.headerCell,
                          ...(index === 0
                            ? { borderRadius: "0.357rem 0 0 0" }
                            : index === 8
                            ? { borderRadius: "0 0.357rem 0 0" }
                            : {}),
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {table1Data.map((row, index) => (
                    <TableRow key={index}>
                      {Object.values(row).map((value, colIndex) => (
                        <TableCell key={colIndex} sx={tableStyles.bodyCell}>
                          {typeof value === "number" ? value.toFixed(2) : value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                  <TableRow>
                    {Object.values(totalRowData).map((value, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          ...tableStyles.totalRowCell,
                          ...(index === 0
                            ? { borderRadius: "0 0 0 0.357rem" }
                            : index === 8
                            ? { borderRadius: "0 0 0.357rem 0" }
                            : {}),
                        }}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box>
              <Typography
                sx={{
                  color: Colors.MAIN_GRAY,
                  fontSize: "1.714285714285714rem",
                  fontWeight: "bold",
                  marginBottom: "-2rem",
                  marginTop: "2rem",
                }}
              >
                Enter the Count
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "2rem",
                width: "100%",
                overflowX: "auto",
              }}
            >
              <RegisterBalancingTable
                data={table2Data}
                table1Data={table1Data}
                totalRowData={totalRowData2}
                editableFields={["cash", "check", "creditCard"]}
                isEditingEnabled={isRegisterBalancingClicked}
              />
            </Box>
          </Box>
        </Box>

        <DayEndButtons
          isRegisterBalancingPage={true}
          onRegisterBalancingClick={() => setIsRegisterBalancingClicked(true)}
        />
      </Box>
    </DayEndLayout>
  );
};

export default RegisterBalancingPage;
