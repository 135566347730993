import { FC, useEffect, useState } from "react";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { useAppDispatch } from "../../../hooks";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";

interface IVariant {
  variant: string;
  cost: string;
}

interface IUpchargeProps {
  id: number;
  upcharge: string;
  variants: IVariant[];
}

const schema = Yup.object().shape({
  upcharge: Yup.string().required("Upcharge is required"),
  variants: Yup.array().of(
    Yup.object().shape({
      variant: Yup.string().required("Variant is required"),
      cost: Yup.string().required("Cost is required"),
    })
  ),
});

export interface ICreateUpchargeDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  upChargeId?: number;
  upchargeContent: string;
  onUpchargesUpdate: (newUpcharge: {
    name: string;
    variantCount: number;
    id: number;
  }) => void;
}

export const AddUpchargesDrawer: FC<ICreateUpchargeDrawerProps> = ({
  open,
  toggleDrawer,
  upChargeId,
  upchargeContent,
  onUpchargesUpdate,
}) => {
  const dispatch = useAppDispatch();
  const [upcharges, setUpcharges] = useState<IUpchargeProps[]>([]);

  const isEdit = !!upchargeContent;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      upcharge: "",
      variants: [],
    },
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "variants",
  });

  useEffect(() => {
    if (!open) {
      reset({ upcharge: "", variants: [] });
      replace([]);
      return;
    }

    if (isEdit && upChargeId !== undefined) {
      const existingUpcharge = upcharges.find((up) => up.id === upChargeId);

      if (existingUpcharge) {
        console.log("Editing Upcharge:", existingUpcharge);

        reset({
          upcharge: existingUpcharge.upcharge || "",
          variants: existingUpcharge.variants || [],
        });

        replace(existingUpcharge.variants ?? []);
      } else {
        console.error("No upcharge found for ID:", upChargeId);
      }
    } else {
      reset({ upcharge: "", variants: [] });
      replace([]);
    }
  }, [open, isEdit, upChargeId, reset, replace, upcharges]);

  const handleAddVariant = () => {
    append({ variant: "", cost: "" });
  };

  const handleAddUpcharge = (data: any) => {
    if (!data.upcharge || data.variants.length === 0) return;

    setUpcharges((prev) => {
      const existingIndex = prev.findIndex(
        (up) => up.upcharge === data.upcharge
      );

      if (existingIndex !== -1) {
        const existingId = prev[existingIndex].id;
        const existingVariants = prev[existingIndex].variants;

        const updatedVariants = [...existingVariants, ...data.variants];

        const updatedUpcharges = [...prev];
        updatedUpcharges[existingIndex] = {
          ...updatedUpcharges[existingIndex],
          variants: updatedVariants,
        };

        onUpchargesUpdate({
          id: existingId,
          name: data.upcharge,
          variantCount: updatedVariants.length,
        });

        return updatedUpcharges;
      } else {
        const newUpchargeId = Date.now();
        const newUpcharge = {
          id: newUpchargeId,
          upcharge: data.upcharge,
          variants: data.variants,
        };

        onUpchargesUpdate({
          id: newUpchargeId,
          name: data.upcharge,
          variantCount: data.variants.length,
        });

        return [...prev, newUpcharge];
      }
    });

    reset({ upcharge: "", variants: [] });
    replace([]);
    toggleDrawer();
  };

  const handleEditUpcharge = (data: any) => {
    if (!data.upcharge || data.variants.length === 0 || !upChargeId) return;

    setUpcharges((prev) =>
      prev.map((upcharge) =>
        upcharge.id === upChargeId
          ? { ...upcharge, upcharge: data.upcharge, variants: data.variants }
          : upcharge
      )
    );

    onUpchargesUpdate({
      id: upChargeId,
      name: data.upcharge,
      variantCount: data.variants.length,
    });

    reset({ upcharge: "", variants: [] });
    toggleDrawer();
  };

  useEffect(() => {
    console.log("Updated Upcharges Array:", upcharges);
  }, [upcharges]);

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{ width: "28.65rem" }}
    >
      <form
        onSubmit={handleSubmit(isEdit ? handleEditUpcharge : handleAddUpcharge)}
      >
        <Box
          sx={{
            paddingInline: "1.857rem",
            paddingTop: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.4rem",
              lineHeight: "1.7rem",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            {isEdit ? "Edit Upcharge" : "Add a New Upcharge"}
          </Typography>

          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "0.85rem" }}
          >
            <Typography>Upcharge Name</Typography>
            <Controller
              name="upcharge"
              control={control}
              defaultValue={isEdit ? upchargeContent : ""}
              render={({ field }) => (
                <CustomTextBox
                  {...field}
                  error={!!errors.upcharge}
                  sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                  placeholder="Enter Upcharge Name"
                />
              )}
            />
          </Box>

          <Divider sx={{ width: "100%" }} />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Variants</Typography>

              <IconButton
                onClick={handleAddVariant}
                sx={{
                  color: "black",
                  padding: 0,
                  "&:hover": { backgroundColor: "transparent" },
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                maxHeight: "49rem",
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "6px",
                },
                pr: "6px",
                mr: "-8px",
              }}
            >
              {fields.length > 0 && (
                <>
                  {console.log("editing", fields)}
                  {fields.map((field, index) => (
                    <Box key={field.id}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                          }}
                        >
                          <Controller
                            name={`variants.${index}.variant`}
                            control={control}
                            render={({ field }) => (
                              <CustomTextBox
                                {...field}
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  marginTop: "1rem",
                                }}
                                placeholder="Variant"
                              />
                            )}
                          />
                          <Controller
                            name={`variants.${index}.cost`}
                            control={control}
                            render={({ field }) => (
                              <CustomTextBox
                                {...field}
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                }}
                                placeholder="Cost"
                              />
                            )}
                          />
                        </Box>

                        <IconButton
                          onClick={() => remove(index)}
                          sx={{
                            color: "red",
                            alignSelf: "center",
                            padding: 0,
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>

                      <Divider
                        sx={{
                          width: "100%",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      />
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "1rem",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "1rem",
              backgroundColor: Colors.WHITE,
            }}
          >
            <CustomButton
              sx={{ width: "9.2rem", height: "2.6rem" }}
              type="submit"
            >
              {isEdit ? "Edit Upcharge" : "Add Upcharge"}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </CustomDrawer>
  );
};
