import React, { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Colors } from "../../../configs";
import { CustomButton } from "../../../components";
import ConfirmationModal from "./ConfirmationModal";
import ClearRegisterModal from "./ClearRegisterModal";

interface DayEndButtonsProps {
  isRegisterBalancingPage?: boolean;
  onRegisterBalancingClick?: () => void;
  onSaveClick?: () => void; 
}

const DayEndButtons: React.FC<DayEndButtonsProps> = ({
  isRegisterBalancingPage,
  onRegisterBalancingClick,
  onSaveClick,
}) => {
  const [isPrintClicked, setIsPrintClicked] = useState(false);
  const [isClearRegisterEnabled, setIsClearRegisterEnabled] = useState(false);
  const [isRegisterBalancingEnabled, setIsRegisterBalancingEnabled] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showClearRegisterModal, setShowClearRegisterModal] = useState(false);

  const navigate = useNavigate();

  const handlePrintClick = () => {
    setIsPrintClicked(true);
    setIsClearRegisterEnabled(true);
  };

  const handleFinishClick = () => {
    if (!isPrintClicked) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const handleClearRegisterClick = () => {
    setShowClearRegisterModal(true);
  };

  const handleCloseClearRegisterModal = () => setShowClearRegisterModal(false);

  const handleClearRegister = () => {
    setShowClearRegisterModal(false);
    setIsRegisterBalancingEnabled(true);
  };

  const handleRegisterBalancingClick = () => {
    navigate("/pos/cash-register/day-end/register-balancing");
  };

  const buttonTexts = [
    "Print Day End Report",
    "Download Day End Report",
    "Finish Day End",
    "Clear Register",
    "Register Balancing",
    "Save",
  ];

  return (
    <>
      <Box
        sx={{
          width: "30rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.6rem",
          borderRadius: "0.7142857142857143rem",
          marginTop: "2.5rem",
        }}
      >
        {buttonTexts.map((text, index) => (
          <CustomButton
            key={index}
            sx={{
              width: "30rem",
              height: "6.571428571428571rem",
              backgroundColor: Colors.WHITE,
              color: Colors.PRIMARY,
              fontSize: "1.428571428571429rem",
              borderRadius: "0.7142857142857143rem",
              border: "none",
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => {
              if (text === "Print Day End Report") {
                handlePrintClick();
              }
              if (text === "Finish Day End") {
                handleFinishClick();
              }
              if (text === "Clear Register") {
                handleClearRegisterClick();
              }
              if (text === "Register Balancing") {
                if (onRegisterBalancingClick) onRegisterBalancingClick();
                handleRegisterBalancingClick();
              }
              if (text === "Save" && onSaveClick) {
                onSaveClick(); 
              }
            }}
            disabled={
              (text === "Clear Register" &&
                (!isClearRegisterEnabled || isRegisterBalancingPage)) ||
              (text === "Register Balancing" &&
                !isRegisterBalancingEnabled &&
                !isRegisterBalancingPage) ||
              (text === "Finish Day End" && isRegisterBalancingPage) ||
              (text === "Save" && !isRegisterBalancingPage)
            }
          >
            {text}
          </CustomButton>
        ))}
      </Box>

      <ConfirmationModal
        open={showModal}
        onClose={handleCloseModal}
        onContinue={() => {
          setShowModal(false);
        }}
        onPrint={() => {
          setShowModal(false);
          handlePrintClick();
        }}
      />
      <ClearRegisterModal
        open={showClearRegisterModal}
        onClose={handleCloseClearRegisterModal}
        onClear={handleClearRegister}
      />
    </>
  );
};

export default DayEndButtons;
