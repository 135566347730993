import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { pxToRem } from "../../../util";
import { Colors } from "../../../configs";

interface RefundModalProps {
  open: boolean;
  onClose: () => void;
}

const sampleData = [
  { method: "Cash", transactions: 3, amount: "$5.34" },
  { method: "Credit Cards", transactions: 2, amount: "$10.25" },
  { method: "Checks", transactions: 4, amount: "$21.00" },
  { method: "Store Credits", transactions: 1, amount: "$11.00" },
];

const commonCellStyles = {
  textAlign: "center",
  verticalAlign: "middle",
  padding: "0.5rem",
  height: pxToRem(52),
};

const getBorderRadius = (
  rowIndex: number,
  cellIndex: number,
  isLastRow: boolean
) => {
  if (rowIndex === 0) {
    return cellIndex === 0
      ? `${pxToRem(10)} 0 0 0`
      : cellIndex === 2
      ? `0 ${pxToRem(10)} 0 0`
      : "0";
  }
  if (isLastRow) {
    return cellIndex === 0
      ? `0 0 0 ${pxToRem(10)}`
      : cellIndex === 2
      ? `0 0 ${pxToRem(10)} 0`
      : "0";
  }
  return "0";
};

const RefundModal: FC<RefundModalProps> = ({ open, onClose }) => {
  return (
    <CustomModal
      title="Refunds - Station 01"
      openModal={open}
      onClose={onClose}
      width={pxToRem(657)}
      height={pxToRem(540)}
    >
      <TableContainer
        sx={{
          borderRadius: pxToRem(10),
          overflow: "hidden",
          marginTop: "3rem",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{ backgroundColor: Colors.SECONDARY, maxHeight: pxToRem(32) }}
            >
              {["Refund Method", "No. of Transactions", "Amount ($)"].map(
                (header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      ...commonCellStyles,
                      color: Colors.WHITE,
                      borderBottom: `${pxToRem(3)} solid ${Colors.WHITE}`,
                      borderRight: `${pxToRem(3)} solid ${Colors.WHITE}`,
                      borderRadius: getBorderRadius(0, index, false),
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {sampleData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  backgroundColor: Colors.BACKSHADE_GRAY,
                  height: pxToRem(52),
                }}
              >
                {[row.method, row.transactions, row.amount].map(
                  (cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      sx={{
                        ...commonCellStyles,
                        color: Colors.MAIN_GRAY,
                        borderBottom: `${pxToRem(3)} solid ${Colors.WHITE}`,
                        borderRight: `${pxToRem(3)} solid ${Colors.WHITE}`,
                        borderRadius: getBorderRadius(
                          rowIndex + 1,
                          cellIndex,
                          false
                        ),
                      }}
                    >
                      {cell}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}

            <TableRow
              sx={{ backgroundColor: Colors.MAIN_GRAY, height: pxToRem(52) }}
            >
              {["Total Refunds", "10", "$47.59"].map((cell, index) => (
                <TableCell
                  key={index}
                  sx={{
                    ...commonCellStyles,
                    color: Colors.WHITE,
                    borderRight: `${pxToRem(3)} solid ${Colors.WHITE}`,
                    borderRadius: getBorderRadius(
                      sampleData.length + 1,
                      index,
                      true
                    ),
                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </CustomModal>
  );
};

export default RefundModal;
