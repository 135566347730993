import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import {
  BaseResponseSingle,
  IdamageResponse,
} from "../../typings/interfaces/responses";
import { ADD_DAMAGE, GET_ALL_DAMAGES } from "../../configs/apiConfig";
import { number } from "yup";
import { IDamageRequest } from "../../typings/interfaces/requests/damageRequests";

export const getAllDamagesAction = createAsyncThunk(
  "damages/all-damages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IdamageResponse>>(
        GET_ALL_DAMAGES
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const CreateDamageAction = createAsyncThunk(
  "damage/create-damage",
  async (
    {
      storeId,
      merchantId,
      damage,
    }: { storeId: number; merchantId: number; damage: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponseSingle<IdamageResponse>>(
        ADD_DAMAGE(merchantId, storeId),
        { merchantId, storeId, damage }
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);
