import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { IUserResponse } from "../../interfaces";
import { IcashierOnboardingResponse } from "../../typings";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setActiveStation } from "../../redux/slice/cashierSlice";
// import { useEffect } from "react";
// import { getCashierStationListAction } from "../../redux/actions/cashierAction";
// import { useSnackbarContext } from "../../providers/SnackbarProvider";
// import { IStation, setStationList } from "../../redux/slice/CashRegisterSlice";

const PrivateRoute = (props: {
  token?: string;
  children: JSX.Element;
  user?: IUserResponse;
  authDetailsInitialized?: boolean;
  disableAdminGuard?: boolean;
  disablePosGuard?: boolean;
}) => {
  const dispatch = useAppDispatch();
  // const snackbar = useSnackbarContext();
  // const { stationList, stationListError } = useAppSelector(
  //   (state) => state.cashier
  // );
  // useEffect(() => {
  //   dispatch(getCashierStationListAction());
  // }, []);
  // useEffect(() => {
  //   if (stationListError) {
  //     snackbar.showSnackbar(stationListError, "error");
  //   }
  // }, [stationListError]);

  // useEffect(() => {
  //   if (stationList) {
  //     const preparedStationList: IStation[] = stationList.map((station) => {
  //       return {
  //         id: station.id,
  //         name: station.name,
  //         cash: {
  //           bills: {
  //             "1": 0,
  //             "5": 0,
  //             "10": 0,
  //             "20": 0,
  //           },
  //           coins: {
  //             "1c": 0,
  //             "5c": 0,
  //             "10c": 0,
  //             "25c": 0,
  //           },
  //         },
  //         closingCash: {
  //           billTotal: 0,
  //           coins: {
  //             "1c": 0,
  //             "5c": 0,
  //             "10c": 0,
  //             "25c": 0,
  //           },
  //         },
  //         totalSales: 0,
  //         registerTotal: 0,
  //         refundTotal: 0,
  //         extraAdditions: [],
  //         extraDeductions: [],
  //         status: "Closed",
  //       };
  //     });
  //     dispatch(setStationList(preparedStationList));
  //   }
  // }, [stationList]);

  if (props.user) {
    if (
      (props.user.role.name === "ADMIN" ||
        props.user.role.name === "MERCHANT_ADMIN") &&
      !props.disableAdminGuard
    ) {
      return <Navigate to={"/admin/dashboard"} />;
    } else if (
      props.user.role.name.includes("CASHIER") &&
      !props.disablePosGuard
    ) {
      return <Navigate to={"/pos/onboarding"} />;
    }
  }
  if (!props.authDetailsInitialized) {
    return <p>Loading...</p>;
  }
  const cashier = localStorage.getItem("cashier");
  const parsedCashier: IcashierOnboardingResponse = cashier
    ? JSON.parse(cashier)
    : null;

  if (!(!parsedCashier || !parsedCashier?.isAccessGranted)) {
    dispatch(setActiveStation(parsedCashier));
  }

  const children =
    (!parsedCashier || !parsedCashier?.isAccessGranted) &&
    props.disableAdminGuard === undefined ? (
      <Navigate to="/pos/onboarding" />
    ) : (
      props.children
    );

  return props.token !== null && props.user !== null ? (
    children
  ) : (
    <Navigate to={"/auth/login"} />
  );
};

const mapStateToProps = (state: any) => state.auth;
export default connect(mapStateToProps, null)(PrivateRoute);
