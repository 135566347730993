import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "../../../util/translation-provider";
import { PosLayout, CustomButton } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import ManagerCodeModal from "../../../components/common/manager-code-modal/ManagerCodeModal";
import { Colors } from "../../../configs";

interface DayEndLayoutProps {
  children: React.ReactNode;
  isDayEndPage?: boolean;
}

export const DayEndLayout: React.FC<DayEndLayoutProps> = ({
  children,
  isDayEndPage,
}) => {
  const [activeButtonType, setActiveButtonType] = useState<
    "viewAll" | "dayEnd" | null
  >(null);
  const [modalType, setModalType] = useState<"viewAll" | "dayEnd" | null>(null);

  const translations = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/pos/cash-register");
  };

  return (
    <PosLayout>
      <>
        <PosInnerPageLayout onClickBack={handleBackClick} title={""}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "2.85714286rem",
                    lineHeight: "3.35714286rem",
                    color: Colors.TEXT_GRAY_DARK,
                    fontWeight: "700",
                    marginLeft: "6rem",
                  }}
                >
                  {translations.companyName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.71428571rem",
                    lineHeight: "1.92857143rem",
                    color: Colors.TEXT_GRAY_DARK,
                    marginLeft: "6rem",
                  }}
                >
                  Cash Register
                </Typography>
              </Box>

              <Box>
                <CustomButton
                  onClick={() => navigate("/pos/lobby")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    marginRight: "1rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                  }}
                >
                  Lobby
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/view-all")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    marginRight: "1rem",
                    backgroundColor:
                      activeButtonType === "viewAll"
                        ? Colors.PRIMARY
                        : Colors.BACKSHADE_GRAY,
                    color:
                      activeButtonType === "viewAll"
                        ? Colors.WHITE
                        : Colors.PRIMARY,
                    border:
                      activeButtonType === "viewAll"
                        ? "none"
                        : `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                  }}
                >
                  View All
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/day-end")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    fontSize: "1.428571428571429rem",
                    borderRadius: "0.7142857142857143rem",
                    backgroundColor: isDayEndPage
                      ? Colors.PRIMARY
                      : activeButtonType === "dayEnd"
                      ? Colors.PRIMARY
                      : Colors.BACKSHADE_GRAY,
                    color: isDayEndPage
                      ? Colors.WHITE
                      : activeButtonType === "dayEnd"
                      ? Colors.WHITE
                      : Colors.PRIMARY,
                    border: isDayEndPage
                      ? "none"
                      : activeButtonType === "dayEnd"
                      ? "none"
                      : `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    marginTop: "6rem",
                    marginRight: "7.5rem",
                  }}
                >
                  Day End
                </CustomButton>
              </Box>
            </Box>
            {children}
          </Box>
        </PosInnerPageLayout>
      </>
    </PosLayout>
  );
};
