import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import {
  Dashboard,
  Person,
  Store,
  PointOfSale,
  Style,
  AttachMoney,
  AssignmentLate,
  PriceChange,
  FmdGood,
} from "@mui/icons-material/";
import {
  BarChart,
  MonetizationOn,
  LocalOffer,
  PeopleAlt,
  StoreOutlined,
  Warning,
} from "@mui/icons-material";

import { useTranslation } from "../../../util/translation-provider";

const menuItems = [
  {
    title: "Dashboard",
    link: "/admin/dashboard",
    icon: <Dashboard sx={{ color: "primary.main" }} />,
  },
  {
    title: "Analytics",
    link: "/admin/analytics",
    icon: <BarChart sx={{ color: "primary.main" }} />,
  },
  {
    title: "Customers",
    link: "/admin/customers",
    icon: <Person sx={{ color: "primary.main" }} />,
  },
];

const settings = [
  {
    title: "Price List",
    link: "/admin/config/upload-price",
    icon: <MonetizationOn sx={{ color: "primary.main" }} />,
  },
  {
    title: "Discounts",
    link: "/admin/discounts",
    icon: <LocalOffer sx={{ color: "primary.main" }} />,
  },
  {
    title: "System Users",
    link: "/admin/customers",
    icon: <PeopleAlt sx={{ color: "primary.main" }} />,
  },
  {
    title: "Rack Config",
    link: "/admin/config/rack-config",
    icon: <PeopleAlt sx={{ color: "primary.main" }} />,
  },
  {
    title: "Merchants",
    link: "/admin/config/merchants",
    icon: <PeopleAlt sx={{ color: "primary.main" }} />,
  },
  {
    title: "Stores",
    link: "/admin/config/merchants/presto/stores?merchantId=1",
    icon: <Store sx={{ color: "primary.main" }} />,
  },
  {
    title: "Cashier Points",
    link: "/admin/config/merchants/presto/stores/1/points",
    icon: <PointOfSale sx={{ color: "primary.main" }} />,
  },
  {
    title: "PinTag Config",
    link: "/admin/config/merchants/presto/stores/1/pin-tag",
    icon: <Style sx={{ color: "primary.main" }} />,
  },
  {
    title: "Casheir Points",
    link: "/admin/cashier-points",
    icon: <AttachMoney sx={{ color: "primary.main" }} />,
  },

  {
    title: "Instructions",
    link: "/admin/instructions",
    icon: <AssignmentLate sx={{ color: "primary.main" }} />,
  },
  {
    title: "Upcharges",
    link: "/admin/upcharges",
    icon: <PriceChange sx={{ color: "primary.main" }} />,
  },
  {
    title: "Spots",
    link: "/admin/spots",
    icon: <FmdGood sx={{ color: "primary.main" }} />,
  },
  {
    title: "Damages",
    link: "/admin/damages",
    icon: <Warning sx={{ color: "primary.main" }} />,
  },
];

const MenuItem: FC<{
  title: string;
  link: string;
  icon: any;
  active: boolean;
}> = (props) => {
  const navigate = useNavigate();
  const { title, link, icon, active } = props;

  const handleOnClick = () => {
    navigate(link);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: active ? "adminMenuItem.main" : "none",
        p: "0.71rem",
        pl: "1rem",
      }}
      onClick={handleOnClick}
    >
      {icon}
      <Box sx={{ pl: "1rem" }}>
        <Typography color="primary.main" fontSize={"1.6rem"}>
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};

export const AdminSideNav = () => {
  const translation = useTranslation();
  const location = useLocation();
  const thisYear = new Date().getFullYear();
  return (
    <Grid
      sx={{
        height: "100%",
        width: "17.8571429rem",
        boxShadow:
          "0.142857143rem 0rem 0.71rem -0.285714286rem rgba(0,0,0,0.18)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid flex={1}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: "0.71rem",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "3.57142857rem",
            }}
          >
            <img
              src={translation.loginLogo}
              alt="LOGO"
              width={180}
              style={{
                objectFit: "contain",
                marginTop: "0.5rem",
              }}
            />
          </Box>
          <Box
            sx={{ pl: "0.71rem", pb: "0.428571429rem", mt: "4.28571429rem" }}
          >
            <Typography
              color="textHeader.main"
              sx={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              MENU
            </Typography>
          </Box>
          <Grid flex={1}>
            {menuItems.map((item) => {
              return (
                <MenuItem
                  link={item.link}
                  title={item.title}
                  icon={item.icon}
                  active={location.pathname === item.link}
                />
              );
            })}
          </Grid>

          <Box
            sx={{ pl: "0.71rem", pb: "0.428571429rem", mt: "2.14285714rem" }}
          >
            <Typography
              color="textHeader.main"
              sx={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              SETTINGS
            </Typography>
          </Box>
          {settings.map((item) => {
            return (
              <MenuItem
                link={item.link}
                title={item.title}
                icon={item.icon}
                active={location.pathname === item.link}
              />
            );
          })}
        </Box>
      </Grid>
      <Grid sx={{ pb: "0.71rem" }}>
        <p style={{ fontSize: "1rem", color: "#515151", textAlign: "center" }}>
          All rights reserved. {thisYear} ©<br /> Teach Me It (Pvt) Ltd
          <br /> <u>Privacy Policy</u> and <u>Terms and Conditions</u>
        </p>
      </Grid>
    </Grid>
  );
};
