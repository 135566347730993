import React, { FC, useState } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton } from "../../../components";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { updateStation } from "../../../redux/slice/CashRegisterSlice";

interface RemoveCashModalProps {
  open: boolean;
  onClose: () => void;
  onRemove: ({ amount, reason }: { amount: number; reason: string }) => void;
}

const RemoveCashModal: FC<RemoveCashModalProps> = ({
  open,
  onClose,
  onRemove,
}) => {
  const [cashAmount, setCashAmount] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  const dispatch = useAppDispatch();
  const { activeStation } = useAppSelector((state) => state.cashRegister);

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const handleCashAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.replace(/[^0-9.]/g, "");
    setCashAmount(value);
  };

  const handleAddClick = () => {
    const amount = parseFloat(cashAmount);
    if (!isNaN(amount) && reason.trim() && activeStation) {
      // dispatch(
      //   updateStation({
      //     ...activeStation,
      //     extraDeductions: [
      //       ...activeStation.extraDeductions,
      //       { amount, reason },
      //     ],
      //   })
      // );
      onRemove({
        amount: amount,
        reason: reason,
      });
      setCashAmount("");
      setReason("");
      onClose();
    }
  };

  return (
    <CustomModal
      title="Remove Cash"
      openModal={open}
      onClose={onClose}
      width="40.28571428571429rem"
      height="27.92857142857143rem"
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "2rem",
            alignItems: "center",
            marginTop: "2.5rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.428571428571429rem",
              color: Colors.MAIN_GRAY,
            }}
          >
            Cash Amount
          </Typography>
          <TextField
            value={cashAmount}
            onChange={handleCashAmountChange}
            placeholder="0.00"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontSize: "1.428571428571429rem",
                      color: Colors.MAIN_GRAY,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
            sx={{
              width: "22.21428571428571rem",
              "& .MuiOutlinedInput-root": {
                height: "2.785714285714286rem",
              },
              "& .MuiOutlinedInput-input": {
                textAlign: "left",
                fontSize: "1.428571428571429rem",
                color: Colors.MAIN_GRAY,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.MAIN_GRAY,
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "2rem",
            alignItems: "center",
            marginTop: "1.5rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.428571428571429rem",
              color: Colors.MAIN_GRAY,
              marginTop: "-2.5rem",
            }}
          >
            Reason
          </Typography>
          <TextField
            value={reason}
            onChange={handleReasonChange}
            placeholder="Enter the reason to remove cash..."
            sx={{
              width: "22.21428571428571rem",
              height: "5.285714285714286rem",
              marginLeft: "3.7rem",
              "& .MuiOutlinedInput-root": {
                height: "5.285714285714286rem",
              },
              "& .MuiOutlinedInput-input": {
                textAlign: "left",
                fontSize: "1.428571428571429rem",
                color: Colors.MAIN_GRAY,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.MAIN_GRAY,
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2.3rem",
          }}
        >
          <CustomButton
            onClick={handleAddClick}
            disabled={!cashAmount.trim() || !reason.trim()}
            sx={{
              width: "33.35714285714286rem",
              height: "4.571428571428571rem",
              backgroundColor: Colors.PRIMARY,
              fontSize: "1.714285714285714rem",
              textTransform: "none",
            }}
          >
            Remove
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default RemoveCashModal;
