import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Colors } from "../../../configs";
import { CustomButton, PosLayout } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import { useTranslation } from "../../../util/translation-provider";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  IStation,
  updateStation,
} from "../../../redux/slice/CashRegisterSlice";

const CloseOutPage = () => {
  const [temporaryStation, setTemporaryStation] = useState<IStation | null>(
    null
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [activeStation, setActiveStation] = useState<IStation | null>(null);

  const { stationList } = useAppSelector((state) => state.cashRegister);
  const { activeStation: activeCashier } = useAppSelector(
    (state) => state.cashier
  );
  const navigate = useNavigate();
  const translations = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const p = localStorage.getItem("activeStation");
    if (p) {
      setActiveStation(JSON.parse(p));
      if (activeStation?.status === "Closed") {
        setIsSubmitted(true);
      }
    } else {
      setActiveStation({
        id: activeCashier?.id ?? 1,
        name: activeCashier?.name ?? "Cashier 1",
        cash: null,
        closingCash: null,
        totalSales: 100,
        registerTotal: 200,
        refundTotal: 5,
        extraAdditions: [],
        extraDeductions: [],
        status: null,
      });
      localStorage.setItem(
        "activeStation",
        JSON.stringify({
          id: activeCashier?.id ?? 1,
          name: activeCashier?.name ?? "Cashier 1",
          cash: null,
          closingCash: null,
          totalSales: 100,
          registerTotal: 200,
          refundTotal: 5,
          extraAdditions: [],
          extraDeductions: [],
          status: null,
        })
      );
    }
  }, [activeCashier]);

  useEffect(() => {
    if (activeStation) {
      setTemporaryStation(activeStation);
    } else {
      setTemporaryStation(null);
    }

    if (activeStation?.status === "Closed") {
      setIsSubmitted(true);
    }
  }, [activeStation]);

  const calculateTotalOpeningBalance = () => {
    if (activeStation?.cash) {
      const { bills, coins } = activeStation.cash;
      const total =
        bills["1"] * 1 +
        bills["5"] * 5 +
        bills["10"] * 10 +
        bills["20"] * 20 +
        coins["1c"] * 0.01 +
        coins["5c"] * 0.05 +
        coins["10c"] * 0.1 +
        coins["25c"] * 0.25;
      return total;
    }
    return 0;
  };

  const calculateClosingCoinsTotal = () => {
    if (temporaryStation?.closingCash) {
      const { coins } = temporaryStation.closingCash;
      const total =
        coins["1c"] * 1 +
        coins["5c"] * 5 +
        coins["10c"] * 10 +
        coins["25c"] * 25;
      return total;
    }
    return 0;
  };
  const calculateOpeningBillsTotal = () => {
    if (temporaryStation?.cash) {
      const { bills } = temporaryStation.cash;
      const total =
        bills["1"] * 1 + bills["5"] * 5 + bills["10"] * 10 + bills["20"] * 20;
      return total;
    }
    return 0;
  };
  const calculateOpeningCoinsTotal = () => {
    if (temporaryStation?.cash) {
      const { coins } = temporaryStation.cash;
      const total =
        coins["1c"] * 0.01 +
        coins["5c"] * 0.05 +
        coins["10c"] * 0.1 +
        coins["25c"] * 0.25;
      return total;
    }
    return 0;
  };
  const onClosingCashChange = (
    cashType: "billTotal" | "1c" | "5c" | "10c" | "25c",
    amount: number
  ) => {
    if (temporaryStation) {
      const coinValues = {
        ...(cashType.endsWith("c") ? { [cashType]: amount } : {}),
      };
      const billTotal =
        cashType === "billTotal"
          ? amount
          : temporaryStation?.closingCash?.billTotal ?? 0;

      const updatedCashValues = {
        billTotal: billTotal,
        coins: {
          ...(temporaryStation?.closingCash?.coins ?? {
            "1c": 0,
            "5c": 0,
            "10c": 0,
            "25c": 0,
          }),
          ...coinValues,
        },
      };
      const updatedStation: IStation = {
        ...temporaryStation,
        closingCash: updatedCashValues,
      };
      // dispatch(updateStation(updatedStation));
      setTemporaryStation(updatedStation);
    }
  };

  const buttonList = [
    {
      label: "Opening Balance",
      disabled: true,
      onClick: () => {},
    },
    {
      label: "Add Cash",
      disabled: true,
      onClick: () => {},
    },
    {
      label: "Remove Cash",
      disabled: true,
      onClick: () => {},
    },
    {
      label: "Close Out",
      disabled: true,
      onClick: () => {},
    },
    {
      label: "SUBMIT",
      disabled:
        temporaryStation?.status === "Closed" ||
        temporaryStation?.status !== "Open" ||
        (temporaryStation?.status === "Open" && !temporaryStation?.closingCash),
      onClick: () => {
        if (temporaryStation) {
          const modifiedStation: IStation = {
            ...temporaryStation,
            status: "Closed",
          };
          // dispatch(updateStation(modifiedStation));
          localStorage.setItem(
            "activeStation",
            JSON.stringify(modifiedStation)
          );
          setActiveStation(modifiedStation);
          setIsSubmitted(true);
        }
      },
    },
  ];

  return (
    <PosLayout>
      <>
        <PosInnerPageLayout title={""}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "2.85714286rem",
                    lineHeight: "3.35714286rem",
                    color: Colors.TEXT_GRAY_DARK,
                    fontWeight: "700",
                    marginLeft: "6rem",
                  }}
                >
                  {translations.companyName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.71428571rem",
                    lineHeight: "1.92857143rem",
                    color: Colors.TEXT_GRAY_DARK,
                    marginLeft: "6rem",
                  }}
                >
                  Cash Register
                </Typography>
              </Box>

              <Box>
                <CustomButton
                  onClick={() => navigate("/pos/lobby")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                    marginRight: "1rem",
                  }}
                >
                  Lobby
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/view-all")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    marginRight: "1rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                  }}
                >
                  View All
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/day-end")}
                  disabled={stationList.some(
                    (station) => station.status !== "Closed"
                  )}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    fontSize: "1.428571428571429rem",
                    borderRadius: "0.7142857142857143rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    marginTop: "6rem",
                    marginRight: "7.5rem",
                  }}
                >
                  Day End
                </CustomButton>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: Colors.BACKSHADE_GRAY,
                margin: "2rem",
                width: "120.7142857142857rem",
                height: "52.92857142857143rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: "0.7142857142857143rem",
                padding: "2rem",
                marginTop: "-1rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: Colors.MAIN_GRAY,
                    fontSize: "2.285714285714286rem",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Close Out -
                  {isSubmitted
                    ? "Report"
                    : temporaryStation?.name ?? "Undefined Station"}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                  }}
                >
                  {/* State 01: Station 01 - Opening Table*/}
                  <Box
                    sx={{
                      width: "40rem",
                      padding: "0.3rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        height: "6rem",
                        textAlign: "center",
                        paddingTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        borderRadius:
                          "0.7142857142857143rem 0.7142857142857143rem 0 0",
                      }}
                    >
                      {activeCashier?.name ?? "Undefined Station"} - Opening
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.SECONDARY,
                          color: Colors.WHITE,
                          padding: "1rem",
                          textAlign: "center",
                          paddingTop: "1.5rem",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        Bills
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.SECONDARY,
                          color: Colors.WHITE,
                          padding: "1rem",
                          textAlign: "center",
                          paddingTop: "1.5rem",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        Coins
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: "0.3rem",
                        maxHeight: "22rem",
                        padding: "0",
                        backgroundColor: Colors.BACKSHADE_GRAY,
                      }}
                    >
                      {/* {denominations.map((item, index) => (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            {item.dollar}
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            x {bills[item.keyDollar] || 0}
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            {item.cent}
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            x {coins[item.keyCent] || 0}
                          </Box>
                        </React.Fragment>
                      ))} */}
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $1
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.bills["1"] || 0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢1
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.coins["1c"] || 0}
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $5
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.bills["5"] || 0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢5
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.coins["5c"] || 0}
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $10
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.bills["10"] || 0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢10
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.coins["10c"] || 0}
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $20
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.bills["20"] || 0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢25
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.ACCENT_GRAY,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x {activeStation?.cash?.coins["25c"] || 0}
                        </Box>
                      </>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Total
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        $ {calculateTotalOpeningBalance()}
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Total
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        ¢ {calculateOpeningCoinsTotal()}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.MAIN_GRAY,
                        color: Colors.WHITE,
                        height: "5rem",
                        padding: "1rem",
                        textAlign: "left",
                        fontSize: "1.428571428571429rem",
                        borderRadius:
                          "0 0 0.7142857142857143rem 0.7142857142857143rem",
                        paddingTop: "1.5rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Total Opening Balance:</span>
                      <span>
                        $
                        {calculateOpeningBillsTotal() +
                          calculateOpeningCoinsTotal()}
                      </span>
                    </Box>
                  </Box>

                  {/* State 01: Station 01 - Closing Table -------------------*/}
                  <Box
                    sx={{
                      width: "40rem",
                      padding: "0.3rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        height: "6rem",
                        textAlign: "center",
                        paddingTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        borderRadius:
                          "0.7142857142857143rem 0.7142857142857143rem 0 0",
                      }}
                    >
                      {activeCashier?.name ?? "Undefined Station"} - Closing
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.BLACK,
                          padding: "1rem",
                          textAlign: "left",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Total Bill Amount:
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: isSubmitted
                            ? Colors.ACCENT_GRAY
                            : Colors.WHITE,
                          color: Colors.BLACK,
                          height: "5rem",
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "3.2rem",
                          }}
                        >
                          $
                        </span>
                        <input
                          type="text"
                          style={{
                            border: "none",
                            outline: "none",
                            width: "100%",
                            textAlign: "center",
                            background: "transparent",
                          }}
                          disabled={isSubmitted}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );

                            onClosingCashChange(
                              "billTotal",
                              Number(sanitizedValue)
                            );
                          }}
                          value={`$ ${
                            temporaryStation?.closingCash?.billTotal ?? 0
                          }`}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 2fr",
                        gap: "0.3rem",
                        marginTop: "0rem",
                      }}
                    >
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢1
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: isSubmitted
                              ? Colors.ACCENT_GRAY
                              : Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange("1c", Number(sanitizedValue));
                            }}
                            disabled={isSubmitted}
                            value={
                              temporaryStation?.closingCash?.coins["1c"] ?? 0
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                            }}
                            value={`¢ ${
                              (temporaryStation?.closingCash?.coins["1c"] ??
                                0) * 1
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢5
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: isSubmitted
                              ? Colors.ACCENT_GRAY
                              : Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange("5c", Number(sanitizedValue));
                            }}
                            disabled={isSubmitted}
                            value={
                              temporaryStation?.closingCash?.coins["5c"] ?? 0
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                            }}
                            value={`¢ ${
                              (temporaryStation?.closingCash?.coins["5c"] ??
                                0) * 5
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢10
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: isSubmitted
                              ? Colors.ACCENT_GRAY
                              : Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange(
                                "10c",
                                Number(sanitizedValue)
                              );
                            }}
                            disabled={isSubmitted}
                            value={
                              temporaryStation?.closingCash?.coins["10c"] ?? 0
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                            }}
                            value={`¢ ${
                              (temporaryStation?.closingCash?.coins["10c"] ??
                                0) * 10
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢25
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: isSubmitted
                              ? Colors.ACCENT_GRAY
                              : Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange(
                                "25c",
                                Number(sanitizedValue)
                              );
                            }}
                            disabled={isSubmitted}
                            value={
                              temporaryStation?.closingCash?.coins["25c"] ?? 0
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                            }}
                            value={`¢ ${
                              (temporaryStation?.closingCash?.coins["25c"] ??
                                0) * 25
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.BLACK,
                          height: "5rem",
                          padding: "1rem",
                          textAlign: "left",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Total Coins Amount:
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.BLACK,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "3.2rem",
                          }}
                        >
                          ¢
                        </span>
                        <input
                          type="text"
                          style={{
                            border: "none",
                            outline: "none",
                            width: "100%",
                            textAlign: "center",
                          }}
                          // onChange={(e) => {
                          //   const sanitizedValue = e.target.value.replace(
                          //     /[^0-9]/g,
                          //     ""
                          //   );
                          //
                          //   handleClosingInputChange(e, "coins", "total");
                          // }}
                          disabled={true}
                          value={`¢ ${calculateClosingCoinsTotal()}`}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.MAIN_GRAY,
                        color: Colors.WHITE,
                        height: "5rem",
                        padding: "1rem",
                        textAlign: "left",
                        fontSize: "1.428571428571429rem",
                        borderRadius:
                          "0 0 0.7142857142857143rem 0.7142857142857143rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.1rem",
                      }}
                    >
                      <span>Total Closing Balance:</span>
                      <span>
                        $
                        {(temporaryStation?.closingCash?.billTotal ?? 0) +
                          calculateClosingCoinsTotal() * 0.01}
                      </span>
                    </Box>
                  </Box>

                  {/* State 02: Balance Summary Table */}
                  {isSubmitted && (
                    <Box
                      sx={{
                        width: "40rem",
                        backgroundColor: Colors.BACKSHADE_GRAY,
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "0.7142857142857143rem",
                        overflow: "hidden",
                        marginTop: "0.3rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.SECONDARY,
                          color: Colors.WHITE,
                          height: "6rem",
                          textAlign: "center",
                          paddingTop: "2rem",
                          fontSize: "1.714285714285714rem",
                          borderRadius:
                            "0.7142857142857143rem 0.7142857142857143rem 0 0",
                        }}
                      >
                        Balance Summary
                      </Box>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "0.3rem",
                          padding: "0.3rem",
                        }}
                      >
                        {[
                          { label: "Opening Balance", value: "$450.00" },
                          { label: "Cash", value: "$10.00" },
                          { label: "Credit Card", value: "$25.00" },
                          { label: "Check", value: "$85.00" },
                          { label: "Total Sales", value: "$85.00" },
                          { label: "Register Total", value: "$185.00" },
                          { label: "Closing Balance", value: "$255.60" },
                        ].map((row, index) => (
                          <React.Fragment key={index}>
                            <Box
                              sx={{
                                backgroundColor: Colors.WHITE,
                                color: Colors.MAIN_GRAY,
                                padding: "1rem",
                                textAlign: "left",
                                fontSize: "1.428571428571429rem",
                                display: "flex",
                                alignItems: "center",
                                height: "5rem",
                                paddingLeft: "3rem",
                                borderRadius:
                                  row.label === "Closing Balance"
                                    ? "0 0 0 0.7142857142857143rem"
                                    : "0",
                              }}
                            >
                              {row.label}
                            </Box>

                            <Box
                              sx={{
                                backgroundColor: Colors.WHITE,
                                height: "5rem",
                                fontSize: "1.428571428571429rem",
                                color: Colors.MAIN_GRAY,
                                textAlign: "right",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                paddingRight: "7rem",
                                borderRadius:
                                  row.label === "Closing Balance"
                                    ? "0 0 0.7142857142857143rem 0"
                                    : "0",
                              }}
                            >
                              {row.value}
                            </Box>
                          </React.Fragment>
                        ))}
                      </Box>
                    </Box>
                  )}

                  {/* Button Section */}
                  {!isSubmitted && (
                    <Box
                      sx={{
                        width: "30rem",
                        backgroundColor: Colors.BACKSHADE_GRAY,
                        display: "flex",
                        flexDirection: "column",
                        gap: "2.5rem",
                        borderRadius: "0.7142857142857143rem",
                        marginTop: "0.5rem",
                      }}
                    >
                      {buttonList.map((button, index) => (
                        <CustomButton
                          key={index}
                          sx={{
                            width: "30rem",
                            height: "6.571428571428571rem",
                            backgroundColor:
                              index === 4 ? Colors.PRIMARY : Colors.WHITE,
                            color: index === 4 ? Colors.WHITE : Colors.PRIMARY,
                            fontSize:
                              index === 4
                                ? "2.285714285714286rem"
                                : "1.428571428571429rem",
                            borderRadius: "0.7142857142857143rem",
                            border: "none",
                            textTransform: "none",
                            fontWeight: "bold",
                          }}
                          disabled={button.disabled}
                          onClick={button.onClick}
                        >
                          {button.label}
                        </CustomButton>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </PosInnerPageLayout>
      </>
    </PosLayout>
  );
};

export default CloseOutPage;
