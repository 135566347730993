import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

const tableHeaders = ["Category", "Items", "Date In", "Due Date", "Cashier In"];

const sampleData = [
  {
    category: "Dry Cleaning",
    items: 6,
    dateIn: "2024/10/07",
    dueDate: "2024/12/15",
    itemsOut: 1,
  },
  {
    category: "Laundry",
    items: 30,
    dateIn: "2024/10/09",
    dueDate: "2024/12/15",
    itemsOut: 2,
  },
  {
    category: "Shoe Repair",
    items: 12,
    dateIn: "2024/05/07",
    dueDate: "2024/12/15",
    itemsOut: 3,
  },
  {
    category: "Household",
    items: 15,
    dateIn: "2024/10/12",
    dueDate: "2024/12/15",
    itemsOut: 4,
  },
  {
    category: "Alterations",
    items: 3,
    dateIn: "2024/05/17",
    dueDate: "2024/12/15",
    itemsOut: 3,
  },
  {
    category: "Fur/Leather",
    items: 6,
    dateIn: "2024/10/07",
    dueDate: "2024/12/15",
    itemsOut: 1,
  },
  {
    category: "Costumes",
    items: 21,
    dateIn: "2024/11/18",
    dueDate: "2024/12/15",
    itemsOut: 5,
  },
  {
    category: "Bed Wash & Fold",
    items: 8,
    dateIn: "2024/09/07",
    dueDate: "2024/12/15",
    itemsOut: 4,
  },
  {
    category: "Miscellaneous",
    items: 7,
    dateIn: "2024/11/23",
    dueDate: "2024/12/15",
    itemsOut: 2,
  },
  {
    category: "Misc. (Taxable)",
    items: 11,
    dateIn: "2024/10/25",
    dueDate: "2024/12/15",
    itemsOut: 1,
  },
  {
    category: "Box Storage",
    items: 9,
    dateIn: "2024/01/11",
    dueDate: "2024/12/15",
    itemsOut: 5,
  },
  {
    category: "New",
    items: 10,
    dateIn: "2024/11/02",
    dueDate: "2024/12/15",
    itemsOut: 2,
  },
];

const DailySnapshotTable3: React.FC = () => {
  return (
    <TableContainer
      sx={{
        width: pxToRem(1561),
        height: pxToRem(610),
        borderRadius: pxToRem(12),
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          maxHeight: pxToRem(610),
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": { width: "1.6rem" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ADADAD",
            border: "0.6rem solid transparent",
            backgroundClip: "padding-box",
            minHeight: "3rem",
          },
          "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
        }}
      >
        <Table
          stickyHeader
          sx={{ tableLayout: "fixed", borderSpacing: pxToRem(2) }}
        >
          <TableHead>
            <TableRow
              sx={{ backgroundColor: Colors.SECONDARY, height: pxToRem(41) }}
            >
              {tableHeaders.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontSize: pxToRem(18),
                    fontWeight: "bold",
                    color: Colors.WHITE,
                    height: pxToRem(41),
                    minHeight: pxToRem(41),
                    lineHeight: pxToRem(41),
                    backgroundColor: Colors.SECONDARY,
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sampleData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: Colors.ACCENT_GRAY,
                  height: pxToRem(41),
                }}
              >
                {Object.entries(row).map(([key, cell], cellIndex, arr) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      fontSize: pxToRem(18),
                      color: Colors.MAIN_GRAY,
                      height: pxToRem(41),
                      minHeight: pxToRem(41),
                      lineHeight: pxToRem(41),
                      padding: "0px",
                      textAlign: "center",
                      borderBottomLeftRadius:
                        index === sampleData.length - 1 && cellIndex === 0
                          ? pxToRem(5)
                          : 0,
                      borderBottomRightRadius:
                        index === sampleData.length - 1 &&
                        cellIndex === arr.length - 1
                          ? pxToRem(5)
                          : 0,
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default DailySnapshotTable3;
