import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  ICashierOpenRequest,
  ISetCashierOpenningBalanceRequest,
} from "../../typings/interfaces/requests";
import {
  BaseResponse,
  BaseResponseSingle,
  ICashierOpenResponse,
  ICashierPoint,
} from "../../typings/interfaces/responses";
import {
  GET_CASHIER_STATION_LIST,
  GET_STATION_ACCESS,
  GRANT_STATION_ACCESS,
  OPEN_CASHIER,
} from "../../configs";

export const openCashierAction = createAsyncThunk(
  "cashier/open",
  async (
    { cashierPointId, userId }: ICashierOpenRequest,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<
        BaseResponseSingle<ICashierOpenResponse>
      >(OPEN_CASHIER, { cashierPointId, userId });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
// export const setCashierOpenningBalance = createAsyncThunk(
//   "cashier/setCashierOpenningBalance",
//   async(
//     { cashierId, stationAccessId, oneDollarBills, fiveDollarBills, tenDollarBills, twentyDollarBills, oneCent, fiveCents, tenCents, twentyFiveCents }: ISetCashierOpenningBalanceRequest,
//     { rejectWithValue }
//   ) => {
//     try{
//       const response = await axios.post<BaseResponseSingle<ICashierOpenResponse>>(
//         OPEN_CASHIER,
//         {
//           cashierId,
//           stationAccessId,
//           oneDollarBills,
//           fiveDollarBills,
//           tenDollarBills,
//           twentyDollarBills,
//           oneCent,
//           fiveCents,
//           tenCents,
//           twentyFiveCents
//         }
//     }
//       /*
//         "cashierId": 1,
//   "stationAccessId":1,
//   "oneDollarBills": 10,
//   "fiveDollarBills": 5,
//   "tenDollarBills": 3,
//   "twentyDollarBills": 2,
//   "oneCent": 50,
//   "fiveCents": 25,
//   "tenCents": 15,
//   "twentyFiveCents": 10
//       */
//   )
// )

export const getStationAccessAction = createAsyncThunk(
  "cashier/getStationAccess",
  async (cashierId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponseSingle<boolean>>(
        GET_STATION_ACCESS(cashierId)
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getCashierStationListAction = createAsyncThunk(
  "cashier/getCashierPointList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<ICashierPoint>>(
        GET_CASHIER_STATION_LIST
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const grantStationAccessAction = createAsyncThunk(
  "cashier/grantStationAccess",
  async (stationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.patch<BaseResponseSingle<ICashierPoint>>(
        GRANT_STATION_ACCESS(stationId)
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
