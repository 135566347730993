import React from "react";
import { Box, Typography, Tabs, Tab, IconButton } from "@mui/material";
import { Dayjs } from "dayjs";
import { Print, Download } from "@mui/icons-material";

import { PosLayout } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import CustomDateTimePicker from "../../../components/atoms/custom-date-time-picker/custom-date-time-picker";
import DailySnapshotTable1 from "./DailySnapshotTable1";
import DailySnapshotTable2 from "./DailySnapshotTable2";
import DailySnapshotTable3 from "./DailySnapshotTable3";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

interface IDailySnapshotScreenProps {
  openTab?: number;
}

export const DailySnapshotScreen: React.FC<IDailySnapshotScreenProps> = ({
  openTab = 0,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(openTab);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <PosLayout>
      <PosInnerPageLayout title={""}>
        <Box sx={{ padding: "1rem -2rem" }}>
          <Typography
            sx={{
              fontSize: pxToRem(48),
              fontWeight: "bold",
              color: Colors.MAIN_GRAY,
              marginTop: "1rem",
              marginLeft: pxToRem(100),
              flex: 1,
            }}
          >
            Daily Snapshot
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "-2rem",
              marginLeft: pxToRem(70),
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTab-root": {
                  background: Colors.BACKSHADE_GRAY,
                  color: Colors.PRIMARY,
                  height: pxToRem(43),
                  minHeight: pxToRem(43),
                  width: "11.9285714286rem",
                  padding: 0,
                  marginTop: "3rem",
                  textTransform: "none",
                },
                "& .Mui-selected": {
                  background: Colors.PRIMARY,
                  color: `${Colors.WHITE} !important`,
                },
                "& .MuiTab-root:first-of-type": {
                  borderRadius: "0.71rem 0 0 0",
                },
                "& .MuiTab-root:nth-of-type(2)": {
                  borderRadius: "0",
                },
                "& .MuiTab-root:nth-of-type(3)": {
                  borderRadius: "0 0.71rem 0 0",
                },
              }}
            >
              <Tab
                label="Orders-outgoing"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
              <Tab
                label="Orders-in"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
              <Tab
                label="Orders-pending"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: Colors.BACKSHADE_GRAY,
            padding: "2rem",
            borderRadius: "0 0.71rem 0.71rem 0.71rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "flex-start",
            height: pxToRem(833),
            width: pxToRem(1726),
            marginLeft: pxToRem(70),
            gap: "1rem",
          }}
        >
          {/* top common content box */}
          <Box
            sx={{
              // backgroundColor: "yellow",
              backgroundColor: Colors.BACKSHADE_GRAY,
              padding: "2rem 5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: pxToRem(20),
                fontWeight: "bold",
                color: Colors.MAIN_GRAY,
                flex: 1,
              }}
            >
              {selectedTab === 0
                ? "2024/07/26 - 00:01am   to   2024/07/26 - 23:59pm"
                : selectedTab === 1
                ? "2024/07/26 - 00:01am   to   2024/07/26 - 23:59pm"
                : "Upcoming Orders - 2024/10/12"}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  marginRight: "1rem",
                }}
              >
                {(selectedTab === 1 || selectedTab === 2) && (
                  <Typography
                    sx={{
                      fontSize: pxToRem(16),
                      fontWeight: "bold",
                      color: Colors.MAIN_GRAY,
                    }}
                  >
                    Select date:
                  </Typography>
                )}
                <CustomDateTimePicker
                  type="date"
                  value={dueDate}
                  onChange={(newValue) => setDueDate(newValue)}
                  sx={{
                    width: pxToRem(151),
                    height: pxToRem(30),
                    fontSize: "1rem",
                    "& .MuiInputBase-root": {
                      height: pxToRem(30),
                    },
                    "& input": {
                      height: pxToRem(30),
                      padding: "0.5rem",
                      fontSize: "1.428rem",
                    },
                  }}
                />
              </Box>

              <IconButton
                sx={{
                  backgroundColor: Colors.BACKSHADE_GRAY,
                  border: `${pxToRem(1)} solid ${Colors.PRIMARY}`,
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  width: pxToRem(45),
                  height: pxToRem(30),
                }}
              >
                <Print sx={{ fontSize: pxToRem(20), color: Colors.PRIMARY }} />
              </IconButton>

              <IconButton
                sx={{
                  backgroundColor: Colors.BACKSHADE_GRAY,
                  border: `${pxToRem(1)} solid ${Colors.PRIMARY}`,
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  width: pxToRem(45),
                  height: pxToRem(30),
                }}
              >
                <Download
                  sx={{ fontSize: pxToRem(20), color: Colors.PRIMARY }}
                />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ marginTop: pxToRem(-10), marginLeft: pxToRem(50) }}>
            {selectedTab === 0 && (
              <>
                <DailySnapshotTable1 />

                <Box
                  sx={{
                    width: pxToRem(965),
                    height: pxToRem(235),

                    border: `${pxToRem(1)} solid ${Colors.MAIN_GRAY}`,
                    borderRadius: pxToRem(10),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: pxToRem(20),
                    marginTop: pxToRem(30),
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",

                      gridTemplateColumns: "auto auto",
                      rowGap: pxToRem(10),
                      columnGap: pxToRem(60),
                    }}
                  >
                    {[
                      ["Total number of Discounts", 0],
                      ["Total number of Cash Payments", 0],
                      ["Total number of More Discounts", 0],
                      ["Total number of Check Payments", 0],
                      ["Total number of Customers", 6],
                      ["Total number of Credit Card Payments", 0],
                      ["Total number of Refunds", 0],
                      ["Total number of Sales", 6],
                    ].map(([label, value], index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: pxToRem(420),
                        }}
                      >
                        <Typography
                          sx={{
                            minWidth: pxToRem(280),
                            fontSize: pxToRem(20),
                            fontWeight: "bold",
                            color: Colors.MAIN_GRAY,
                          }}
                        >
                          {label}
                        </Typography>
                        <Typography>:</Typography>
                        <Typography
                          sx={{
                            minWidth: pxToRem(30),
                            textAlign: "left",
                            fontSize: pxToRem(20),
                            fontWeight: "bold",
                            color: Colors.MAIN_GRAY,
                          }}
                        >
                          {value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}

            {selectedTab === 1 && <DailySnapshotTable2 />}
            {selectedTab === 2 && <DailySnapshotTable3 />}
          </Box>
        </Box>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default DailySnapshotScreen;
